@import 'resources/scss/_foundation.scss';
.select {
  margin-bottom: spacing(24);

  &.select-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .select__label {
    font-size: font-size(xs);
    line-height: line-height(xs);
    color: color(black, light-2);
    padding-bottom: spacing(6);
    transition: transition();
    text-align: left;
  }
  .select__input {
    height: spacing(48);

    padding: 0;
    font-size: font-size();
    line-height: line-height();
    color: color(black);
    width: 100%;
    position: relative;

    .select__input__header {
      position: relative;
      z-index: 50;
      height: spacing(48);
      border: 1px solid color(black, light-3);
      background-color: color(white);
      transition: transition();
      border-radius: border-radius();
      cursor: pointer;

      .select__input__header__title {
        height: spacing(48);
        line-height: spacing(48);
        padding: 0 spacing(48) 0 spacing(12);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: color(black, light-2);
        font-size: font-size();
        text-align: left;
        &::-moz-selection {
          background: transparent;
        }
        &::selection {
          background: transparent;
        }

        &.select__input__header__title-selected {
          color: color(black);
        }
      }
      .select__input__header__arrow {
        position: absolute;
        right: calc(spacing(6) / 2);
        top: spacing(42);
        transform: rotate(90deg);
        &:before {
          top: spacing(18)-4;
          height: spacing(6);
          right: spacing(18);
          border-right: 2px solid color(black);
          transform: rotate(-45deg);
          position: absolute;
          content: '';
        }
        &:after {
          top: spacing(24)-6;
          height: spacing(6);
          right: spacing(18);
          border-right: 2px solid color(black);
          transform: rotate(45deg);
          position: absolute;
          content: '';
        }
      }
    }
    &.select__input-open {
      z-index: 100;
      .select__input__header {
        box-shadow: shadow(2);
        .select__input__header__arrow {
          &:before {
            transform: rotate(45deg);
          }
          &:after {
            transform: rotate(-45deg);
          }
        }
      }
    }
    .select__input__options {
      background-color: color(white);
      text-align: left;
      border: 1px solid color(black, light-3);
      transition: transition();
      border-radius: border-radius();
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      padding-top: spacing(48);
      width: 100%;
      box-shadow: shadow(3);
      .select__input__options__item {
        padding: spacing(12) spacing(42) spacing(12) spacing(12);
        color: color(black, light-1);
        font-size: font-size(s);
        line-height: line-height(xs);
        cursor: pointer;
        transition: transition();
        position: relative;
        border-bottom: 1px solid color(black, light-5);
        &::-moz-selection {
          background: transparent;
        }
        &::selection {
          background: transparent;
        }
        &:hover {
          background-color: color(black, light-6);
          color: color(black);
        }
        &.select__input__options__item-selected {
          color: color(black);
        }

        .select__input__options__item__check {
          position: absolute;
          top: spacing(6);
          right: spacing(6);
          &:before {
            top: spacing(18)-4;
            height: spacing(6);
            right: spacing(18);
            border-right: 2px solid color(ui-green);
            transform: rotate(-45deg);
            position: absolute;
            content: '';
          }
          &:after {
            top: spacing(12)-2;
            height: spacing(12);
            right: spacing(12);
            border-right: 2px solid color(ui-green);
            transform: rotate(45deg);
            position: absolute;
            content: '';
          }
        }
      }
    }
  }

  .select__help-text {
    font-size: font-size(xs);
    line-height: line-height(xs);
    color: color(black, light-2);
    font-style: italic;
    padding: spacing(6) 0;
  }
  .select__error-message {
    font-size: font-size(xs);
    line-height: line-height(xs);
    color: color(ui-red);
    padding: spacing(6) 0;
    & + .select__help-text {
      padding-top: 0;
    }
  }

  &.select-error {
    .select__input {
      border: 1px solid color(ui-red);
    }
    &.select-required {
      position: relative;
      &:before {
        content: 'Requerido';
        color: color(ui-red);
        position: absolute;
        right: spacing(18);
        top: 0;
        font-size: font-size(xs);
        line-height: spacing(48);
      }
      &.select-label {
        &:before {
          right: spacing(18);
          top: spacing(24);
        }
      }
    }
  }

  &.select-half {
    display: inline-block;
    width: 50%;
    vertical-align: bottom;
    padding-right: spacing(3);
    padding-left: spacing(3);
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  &.select-third {
    display: inline-block;
    width: 33.3333%;
    vertical-align: bottom;
    padding-right: spacing(3);
    padding-left: spacing(3);
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  &.select-two-third {
    display: inline-block;
    width: 66.6666%;
    vertical-align: bottom;
    padding-right: spacing(3);
    padding-left: spacing(3);
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  &.select-fourth {
    display: inline-block;
    width: 25%;
    vertical-align: bottom;
    padding-right: spacing(6);
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  &.select-focus {
    .select__label {
      color: color(black);
    }
    &.select-error {
      .select__input {
        border: 1px solid color(black);
      }
    }
  }
  &.select-file {
    .select__input {
      line-height: 0;
      padding: spacing(12);
    }
  }
}
