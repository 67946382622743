@import 'resources/scss/_foundation.scss';
.breadcrumbs {
  padding: spacing(18) 0;
  border-bottom: 1px solid color(white, dark-2);

  .breadcrumbs__section {
    display: inline-block;
    padding: 0 spacing(6);
    color: color(black, light-3);
    font-size: font-size(xs);
    line-height: line-height(xs);
    a {
      text-transform: uppercase;
      color: color(black, light-3);
      transition: transition();
      text-decoration: none;
      &:hover {
        color: color(black, light-1);
      }
    }
    .breadcrumbs__section__spacer {
      padding-left: spacing(6);
      display: inline-block;
    }
  }

  &.breadcrumbs-dark {
    border-bottom: 1px solid rgbaColor(color(white), opacity(12));
    .breadcrumbs__section {
      color: rgbaColor(color(white), opacity(24));
      a {
        color: rgbaColor(color(white), opacity(50));

        &:hover {
          color: rgbaColor(color(white), opacity(80));
        }
      }
    }
  }
}
