@import 'resources/scss/_foundation.scss';

.card {
  background-color: color(white);
  box-shadow: box-shadow(1);
  border-radius: border-radius();
  box-shadow: shadow(3), shadow(0, 1px);
  padding: spacing(36);

  //Mobile
  @media only screen and (max-width: breakpoint(mobileHD)) {
    padding: spacing(12);
  }
  &.card-nopadding {
    padding: 0 !important;
  }
}
