$animation-speed-map: (
  basic: 0.3s,
  slow: 1s,
  fast: 0.1s
);

$animation-easing-map: (
  basic: ease-in-out,
  system: linear,
  enter: ease-in,
  exit: ease-out
);

@function transition($speed: basic, $easing: basic, $properties: all) {
  $speed-fetched-value: map-get($animation-speed-map, $speed);
  $easing-fetched-value: map-get($animation-easing-map, $easing);

  @if type-of($speed-fetched-value) == number {
    //@return  all 1s ease-in-out;
    @if type-of($easing-fetched-value) == string {
      @return $properties $speed-fetched-value $easing-fetched-value;
    } @else {
      @error 'Easing variant `#{$easing}` not found. Available variants: #{available-names($animation-easing-map)}';
    }
  } @else {
    @error 'Speed variant `#{$speed}` not found. Available variants: #{available-names($animation-speed-map)}';
  }
}

@keyframes arrowAnimation {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(6px);
  }
  100% {
    transform: translateX(0px);
  }
}
