@import 'resources/scss/_foundation.scss';
.model-card {
  text-align: center;
  position: relative;
  padding: spacing(54) 0 spacing(48);
  background-color: color(white);
  transition: transition();
  width: 100%;
  border-radius: border-radius();
  margin-bottom: spacing(24);

  //Mobile
  @media only screen and (max-width: breakpoint(tabletSD)) {
    padding: spacing(30) 0 spacing(36);
    box-shadow: shadow(3), shadow(0, 1px);
  }

  .model-card__link {
    position: absolute;
    display: block;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
  }
  .model-card__model {
    margin: 0;
    padding: 0;
    transition: transition();
    color: color(black, light-1);
    text-transform: capitalize;
    span {
      text-transform: lowercase;
    }
  }
  .model-card__image {
    padding: spacing(18) spacing(12) 0;
    opacity: 0.9;
    height: 160px;
    overflow: hidden;
    transition: transition();
    img {
      display: block;
      max-width: 80%;
      max-height: 140px;
      width: auto;
      height: auto;
      margin: 0 auto;
      //Mobile
      @media only screen and (max-width: breakpoint(tabletSD)) {
        max-width: 90%;
      }
    }
    //Mobile
    @media only screen and (max-width: breakpoint(tabletSD)) {
      padding-top: 0;
    }
  }
  .model-card__price {
    font-size: font-size(s);
    line-height: line-height(s);
    color: color(black, light-1);
    transition: transition();
  }
  .model-card__discount {
    font-size: font-size(s);
    line-height: line-height(s);
    color: color(black, light-2);
    transition: transition();
  }
  .model-card__arrowlink {
    opacity: 0;
    transition: transition();
    font-size: font-size(s);
    line-height: line-height(s);
    color: color(orange);
    //Mobile
    @media only screen and (max-width: breakpoint(tabletSD)) {
      opacity: 1;
    }
  }
  &:hover {
    box-shadow: shadow(4), shadow(0, 1px);
    .model-card__image {
      opacity: 1;
    }
    .model-card__model {
      color: color(black);
    }
    .model-card__price {
      color: color(black);
    }
    .model-card__arrowlink {
      opacity: 1;
    }
  }
}
