@import 'resources/scss/_foundation.scss';
.sticky-nav {
  text-align: left;
  background-color: color(white);
  width: 100%;
  position: absolute;
  top: 0;
  border-bottom: 1px solid color(white, dark-2);
  height: spacing(54);
  transition: transition();

  &.sticky-nav-sticky {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9000;
    box-shadow: shadow(2);
  }
  &.sticky-nav-big {
    height: spacing(72);
    background-color: transparent;

    .sticky-nav__section {
      a {
        padding: spacing(24) spacing(18) (spacing(18) - 1) spacing(18);
      }
    }
  }

  .sticky-nav__container {
    white-space: nowrap;
    overflow-x: auto;
  }
  .sticky-nav__section {
    display: inline-block;
    font-weight: 700;
    a {
      padding: spacing(12) spacing(18) (spacing(12) - 1) spacing(18);
      text-decoration: none;
      display: inline-block;
      color: color(black, light-2);
      transition: transition();
      &:hover {
        color: color(black);
      }
    }
    &.sticky-nav__section-active {
      a {
        color: color(orange);
        border-bottom: 1px solid color(orange);
        &:hover {
          color: color(orange, dark-1);
        }
      }
    }
  }
}
