@import 'resources/scss/_foundation.scss';
.howToBuyBanner {
  background-color: color(black, light-3);
  position: relative;
  color: color(black);
  padding: spacing(30) 0 spacing(30);
  //Mobile
  @media only screen and (max-width: breakpoint(mobileHD)) {
    padding: spacing(48) 0 spacing(48);
  }
  p {
    color: color(black, light-1);
    margin: 0;
    padding: 0;
  }
  .button {
    margin: 0;
  }
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: overlay;
    opacity: 0.5;
    content: '';
    background: linear-gradient(
      to right,
      rgbaColor(color(white, dark-1), 1) 40%,
      rgbaColor(color(white, dark-1), 0) 100%
    );
    //Mobile
    @media only screen and (max-width: breakpoint(tabletSD)) {
      background: linear-gradient(
        to right,
        rgbaColor(color(white, dark-1), 1) 40%,
        rgbaColor(color(white, dark-1), 0.9) 100%
      );
    }
  }
}
