@import 'resources/scss/_foundation.scss';
.layer {
  &.layer-1 {
    box-shadow: shadow(1);
  }
  &.layer-2 {
    box-shadow: shadow(2);
  }
  &.layer-3 {
    box-shadow: shadow(3);
  }
  &.layer-4 {
    box-shadow: shadow(4);
  }
}
