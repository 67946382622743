@import 'resources/scss/_foundation.scss';
.units {
  .mobile__units__title {
    text-transform: capitalize;
  }
  .units-characteristics {
    .units-characteristics__title {
      text-transform: capitalize;
    }
    .units-characteristics__list {
      margin-bottom: spacing(12);
      padding: spacing(12) 0;
      border-top: 1px solid color(black, light-4);
      border-bottom: 1px solid color(black, light-4);
      .units-characteristics__list__item {
        display: inline-block;
        padding: spacing(3) spacing(18);
        color: color(black, light-2);
        font-size: font-size(s);
        position: relative;
        //Mobile
        @media only screen and (max-width: breakpoint(tabletSD)) {
          display: block;
        }
        &:before {
          content: '';
          width: spacing(6);
          height: spacing(6);
          background-color: color(black, light-3);
          position: absolute;
          left: 0;
          top: spacing(12) + 2px;
          display: inline-block;
        }
      }
    }
  }
}
