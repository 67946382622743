@import '_foundation.scss';

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

body,
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  position: relative;
  font-family: font-family();
  line-height: line-height();
  font-size: $basicFontSize;
  background-color: color(white, dark-1);
}

a {
  text-decoration: underline;
  cursor: pointer;
  color: color(orange);
  &:hover {
    color: color(orange, dark-2);
    text-decoration: underline;
  }
}

@media only screen and (max-width: breakpoint(tabletHD)) {
  body {
    padding-top: spacing(78) !important;
  }
}

@import 'globals/_grid.scss';
@import 'globals/_headings.scss';
@import 'globals/_paragraphs.scss';
@import 'globals/_backgrounds.scss';
@import 'globals/_layers.scss';
@import 'globals/_roundness.scss';
@import 'globals/_gradients.scss';
@import 'globals/_sections.scss';
@import 'globals/_tables.scss';
