@import 'resources/scss/_foundation.scss';
//Paragraphs

p {
  font-size: font-size();
  line-height: line-height();
  margin-top: 0;
  color: color(black, light-1);
  margin-bottom: calc(line-height() / 2);
  padding-top: 0;
  padding-bottom: 0;
  max-width: 540px;

  &.p-s {
    font-size: font-size(s);
    line-height: line-height(s);
    max-width: 480px;
  }
  &.p-xs {
    font-size: font-size(xs);
    line-height: line-height(xs);
    max-width: 420px;
  }
  &.p-disclamer {
    font-size: font-size(xs);
    line-height: line-height(xs);
    max-width: 95%;
    color: color(black, light-2);
  }

  &.p-b {
    font-size: font-size(b);
    line-height: line-height(b);
    font-weight: 400;
    max-width: 870px;
    //Mobile
    @media only screen and (max-width: breakpoint(tabletSD)) {
      font-size: font-size(b-mobile);
      line-height: line-height(b-mobile);
    }
  }

  &.p-fluid {
    max-width: 100% !important;
  }

  & + .button {
    margin-top: spacing(18);
  }
}

hr {
  border-color: color(black, light-4) !important;
  border-top: 1px solid;
  border-bottom: none;
  border-left: none;
  border-right: none;
}

//Tablet
@media only screen and (max-width: breakpoint(tabletHD)) {
}
//Mobile
@media only screen and (max-width: breakpoint(mobileHD)) {
}
