@import 'resources/scss/_foundation.scss';
.tabs {
  padding: spacing(24) 0 0;
  .tabs__title {
    h5 {
      color: color(black, light-2);
    }
  }
  .tabs__container {
    border: 1px solid color(black, light-4);
    box-shadow: 0 2px 0 color(white);
    border-radius: border-radius(xb);
    background-color: color(white);
    padding: 0 spacing(24);
    height: spacing(48);
    line-height: spacing(48);
    display: inline-block;
    white-space: nowrap;
  }
  .tabs__section {
    display: inline-block;
    padding: 0 spacing(12);
    font-weight: 700;
    &.tabs__section-active {
      a, .tabs__link {
        color: color(orange);
        &:hover {
          color: color(orange, dark-1);
        }
      }
    }
    a, .tabs__link {
      cursor: pointer;
      text-decoration: none;
      color: color(black, light-2);
      transition: transition();
      &:hover {
        color: color(black);
      }
    }
  }
}
