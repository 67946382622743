@import 'resources/scss/_foundation.scss';
.footer {
  background-color: color(white, dark-2);
  height: spacing(120);
  &.footer-alt {
    background-color: color(white, dark-1);
  }
  //Mobile
  @media only screen and (max-width: breakpoint(tabletSD)) {
    height: auto;
    padding-bottom: spacing(120);
    text-align: center;
  }
  .footer__info {
    font-size: font-size(xs) !important;
    line-height: line-height(xs);
    text-align: right;
    color: color(black, light-2);
    padding-top: spacing(36);
    .footer__info__text {
      display: inline-block;
    }
    .footer__info__social {
      display: inline-block;
      margin-left: spacing(24);
      a {
        margin: 0 spacing(3);
        position: relative;
        top: spacing(3);
        opacity: opacity(24);
        transition: transition();
        &:hover {
          opacity: 1;
        }
      }
    }
    //Mobile
    @media only screen and (max-width: breakpoint(tabletSD)) {
      text-align: center;
      .footer__info__social {
        margin-left: 0;
        margin-top: spacing(12);
      }
    }
  }
  .footer__logo {
    display: inline-block;
    margin-top: spacing(48)-3px;
    margin-right: spacing(24);
    img {
      opacity: opacity(24);
      filter: grayscale(100%);
    }
  }
  //Mobile
  @media only screen and (max-width: breakpoint(tabletSD)) {
    .col-m-twelve {
      margin: 0 !important;
    }
  }
}
