@import 'resources/scss/_foundation.scss';
.digital-dealership {
  padding-bottom: spacing(180);
  .digital-dealership__start {
  }
  .digital-dealership__modal-content {
    padding: spacing(120) spacing(24) spacing(60) !important;
    //Mobile
    @media only screen and (max-width: breakpoint(mobileHD)) {
      padding: spacing(96) spacing(12) spacing(30) !important;
    }
  }
  .digital-dealership__fieldset {
    padding: spacing(30) 0;
    margin: spacing(30) 0 spacing(24);
    border-bottom: 1px solid color(black, light-4);
    &:first-child {
      padding-top: 0;
    }
    h4 {
      margin-bottom: spacing(24);
      color: color(black, light-1);
    }
    &.digital-dealership__fieldset-card {
      max-width: 300px;
    }
  }
  .digital-dealership__cards-container {
    margin: 0 - spacing(6);
  }
  .PaymentMethodRow {
    padding: spacing(36);
    border-bottom: 1px solid color(white, dark-2);
    //Mobile
    @media only screen and (max-width: breakpoint(mobileHD)) {
      padding: spacing(18);
    }

    .PaymentMethodRow__header {
    }
    .PaymentMethodRow__item {
      padding: spacing(12);
      background-color: color(blue, light-5);
      margin-bottom: spacing(18);
      border-radius: border-radius(s);
      display: flex;
      h4,
      h5 {
        margin-bottom: 0;
      }
      .PaymentMethodRow__item__data {
        width: 100%;
      }
      .PaymentMethodRow__item__price {
        text-align: right;
        min-width: 30%;
      }
    }
    .PaymentMethodRow__content {
      display: flex;

      .PaymentMethodColumn {
        vertical-align: top;
        &:first-child {
          width: 100%;
        }
        &:last-child {
          text-align: right;
          min-width: 30%;
        }
        * {
          vertical-align: top;
        }
      }
      //Mobile
      @media only screen and (max-width: breakpoint(mobileHD)) {
        display: block;
        .PaymentMethodColumn {
          &:last-child {
            text-align: left;
            min-width: 100%;
          }
        }
      }
    }
  }
}
.digital-dealership__payment--linkingpos {
  padding: 1em;
  background-color: #fff;
}
