@import 'resources/scss/_foundation.scss';
.textfield {
  margin-bottom: spacing(24);
  .textfield__label {
    font-size: font-size(xs);
    line-height: line-height(xs);
    color: color(black, light-2);
    padding-bottom: spacing(6);
    transition: transition();
    text-align: left;
  }
  .textfield__input {
    height: spacing(48);
    border: 1px solid color(black, light-3);
    transition: transition();
    border-radius: border-radius();
    padding: 0 spacing(12);
    font-size: font-size();
    line-height: line-height();
    color: color(black);
    width: 100%;
    &:focus {
      border: 1px solid color(black, light-1);
    }
    &::placeholder {
      color: color(black, light-2);
    }
  }
  .textfield__help-text {
    font-size: font-size(xs);
    line-height: line-height(xs);
    color: color(black, light-2);
    font-style: italic;
    padding: spacing(6) 0;
  }
  .textfield__error-message {
    font-size: font-size(xs);
    line-height: line-height(xs);
    color: color(ui-red);
    padding: spacing(6) 0;
    & + .textfield__help-text {
      padding-top: 0;
    }
  }

  &.textfield-error {
    .textfield__input {
      border: 1px solid color(ui-red);
    }
    &.textfield-required {
      position: relative;
      &:before {
        content: 'Requerido';
        color: color(ui-red);
        position: absolute;
        right: spacing(18);
        top: 0;
        font-size: font-size(xs);
        line-height: spacing(48);
      }
      &.textfield-label {
        &:before {
          right: spacing(18);
          top: spacing(24);
        }
      }
    }
  }

  &.textfield-half {
    display: inline-block;
    width: 50%;
    vertical-align: bottom;
    padding-right: spacing(3);
    padding-left: spacing(3);
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  &.textfield-third {
    display: inline-block;
    width: 33.3333%;
    vertical-align: bottom;
    padding-right: spacing(3);
    padding-left: spacing(3);
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  &.textfield-two-third {
    display: inline-block;
    width: 66.6666%;
    vertical-align: bottom;
    padding-right: spacing(3);
    padding-left: spacing(3);
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  &.textfield-fourth {
    display: inline-block;
    width: 25%;
    vertical-align: bottom;
    padding-right: spacing(6);
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  &.textfield-focus {
    .textfield__label {
      color: color(black);
    }
    &.textfield-error {
      .textfield__input {
        border: 1px solid color(black);
      }
    }
  }
  &.textfield-file {
    .textfield__input {
      line-height: 0;
      padding: spacing(12);
      background-color: color(white);
    }
  }
}
