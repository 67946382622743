@import 'resources/scss/_foundation.scss';
.modal-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: spacing(60);
  border-bottom: 1px solid color(white, dark-2);
  background-color: color(white);
  z-index: 9999;
  .modal-header__title {
    line-height: spacing(60);
    text-align: center;
    width: 100%;
    font-size: font-size(xs);
    text-transform: uppercase;
    font-weight: 700;
  }
  .modal-header__link {
    line-height: spacing(60);
    width: spacing(60);
    position: absolute;
    right: 0;
    top: 0;
  }
  &.modal-header-dark {
    border-bottom: none;
    background-color: color(black);
    color: color(white);
    &.modal-header-close {
      .modal-header__link {
        &:before, &:after {
          border-right: 2px solid color(white) !important;
        }
      }
    }
    &.modal-header-back {
      .modal-header__link {
        &:before, &:after {
          border-right: 2px solid color(white) !important;
        }
      }
    }
  }
  &.modal-header-close {
    .modal-header__link {
      font-size: 0;
      &:before {
        border-right: 2px solid color(black);
        content: '';
        position: absolute;
        top: spacing(24) - 1;
        left: spacing(30);
        transform: rotate(45deg);
        height: spacing(12);
      }
      &:after {
        border-right: 2px solid color(black);
        content: '';
        position: absolute;
        top: spacing(24) - 1;
        left: spacing(30);
        transform: rotate(-45deg);
        height: spacing(12);
      }
    }
  }
  &.modal-header-back {
    .modal-header__link {
      right: auto;
      left: 0;
      font-size: 0;
      top: spacing(6);
      &:before {
        border-right: 2px solid color(black);
        content: '';
        position: absolute;
        top: spacing(18) - 1;
        left: spacing(30);
        transform: rotate(45deg);
        height: spacing(9);
      }
      &:after {
        border-right: 2px solid color(black);
        content: '';
        position: absolute;
        top: spacing(24) - 1;
        left: spacing(30);
        transform: rotate(-45deg);
        height: spacing(9);
      }
    }
  }

  //Units
  &.modal-header-units {
    height: auto;
    padding: spacing(30) 0 spacing(24);
    h4 {
      padding: 0 0 spacing(6);
      margin: 0;
      text-transform: capitalize;
    }
    h2 {
      padding: 0;
      margin: 0;
      text-transform: capitalize;
    }
    .modal-header__units-image {
      display: block;
      max-width: 80%;
      max-height: spacing(84);
      width: auto;
      height: auto;
      margin: 0 auto;
    }
    .modal-header__buy-text {
      p {
        color: color(black, light-2);
      }
    }
  }
}
