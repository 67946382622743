@import 'resources/scss/_foundation.scss';
.hero {
  padding: spacing(120) 0;

  &.hero-small {
    padding: spacing(36) 0 0;
  }
  &.hero-big {
    padding: spacing(60) 0 spacing(120) * 2;
    &.hero-image {
      .hero__image {
        height: 120%;
        top: -15%;
        left: 45%;
        //Mobile
        @media only screen and (max-width: breakpoint(tabletSD)) {
          position: relative;
          top: auto;
          left: auto;
          text-align: center;
          img {
            width: 70%;
            margin-top: 0;
          }
        }
      }
    }
  }
  //Mobile
  @media only screen and (max-width: breakpoint(tabletSD)) {
    padding: spacing(60) 0;
    text-align: center;
    &.hero-big {
      padding: spacing(60) 0;
    }
    &.hero-background {
      text-align: left;
    }
    &.hero-small-mobile {
      padding: spacing(24) 0 !important;
    }
  }

  .hero__breadcrumbs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &.hero-color {
    background-color: color(black);
    h1,
    h2,
    h3,
    p {
      color: color(white);
    }
    &.hero-orange {
      background-color: color(orange);
    }
    &.hero-blue {
      background-color: color(blue);
    }
    &.hero-yellow {
      background-color: color(yellow);
    }
  }
  &.hero-image {
    position: relative;
    overflow: hidden;
    .hero__image {
      position: absolute;
      height: 100%;
      top: 0;
      left: 50%;

      img {
        max-height: 90%;
        margin-top: 5%;
      }
      //Mobile
      @media only screen and (max-width: breakpoint(tabletSD)) {
        position: relative;
        top: auto;
        left: auto;
        text-align: center;
        img {
          width: 70%;
          margin-top: 0;
        }
      }
    }
  }

  &.hero-background {
    background-size: cover;
    background-position: center;
    overflow: hidden;
    position: relative;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      opacity: 0.98;
      content: '';
      background: linear-gradient(
        to right,
        rgbaColor(color(white, dark-1), 1) 40%,
        rgbaColor(color(white, dark-1), 0) 100%
      );
      //Mobile
      @media only screen and (max-width: breakpoint(tabletSD)) {
        background: linear-gradient(
          to right,
          rgbaColor(color(white, dark-1), 1) 40%,
          rgbaColor(color(white, dark-1), 0.9) 100%
        );
      }
    }
    &.hero-dark {
      background-color: color(black);
      &:before {
        background: linear-gradient(
          to right,
          rgbaColor(color(black), 1) 40%,
          rgbaColor(color(black), 0) 100%
        );
        //Mobile
        @media only screen and (max-width: breakpoint(tabletSD)) {
          background: linear-gradient(
            to right,
            rgbaColor(color(black), 1) 40%,
            rgbaColor(color(black), 0.9) 100%
          );
        }
      }
      h1,
      h2,
      h3,
      p,
      div {
        color: color(white);
      }
    }
    &.hero-breadcrumbs {
      padding-top: spacing(180);
      //Mobile
      @media only screen and (max-width: breakpoint(tabletSD)) {
        padding-top: spacing(120);
      }
    }
  }
}
