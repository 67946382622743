@import 'resources/scss/_foundation.scss';
.mobile-actions {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: spacing(96);
  text-align: center;
  background-color: color(white, dark-2);
  z-index: 8888;
  box-shadow: shadow(3, $inverted: true);
  .mobile-actions__actions {
    padding: spacing(12);
  }
  &.mobile-actions-transparent {
    background-color: transparent;
  }
}
