@import 'resources/scss/_foundation.scss';

.arrowlink {
  font-size: font-size();
  line-height: line-height();
  background-color: transparent;
  border: none;
  padding: 0 spacing(24) 0 0;
  margin: spacing(6) 0 spacing(6) 0;
  color: color(orange);
  position: relative;
  cursor: pointer;
  letter-spacing: 0;
  & + .arrowlink {
    margin: spacing(6) 0 spacing(6) spacing(24);
  }
  .arrowlink__arrow {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 2px;
    width: spacing(12);
    height: spacing(12);
    background-repeat: no-repeat;
    background-position: center;
  }

  &:hover {
    color: color(orange, dark-1);
    .arrowlink__arrow {
      animation: arrowAnimation 1s ease-in-out infinite both;
    }
  }

  &.arrowlink-blue {
    color: color(blue);
    &:hover {
      color: color(blue, dark-1);
    }
  }

  &.arrowlink-yellow {
    color: color(yellow, dark-1);
    &:hover {
      color: color(yellow, dark-2);
    }
  }

  &.arrowlink-white {
    color: color(white);
    &:hover {
      color: color(white);
      opacity: opacity(80);
    }
  }

  &.arrowlink-disabled {
    color: color(black, light-3);
    pointer-events: none;
    &:hover {
      color: color(black, light-3);
    }
    .arrowlink__arrow {
      filter: grayscale(100%);
      opacity: opacity(24);
    }
  }

  &.arrowlink-small {
    font-size: font-size(s);
    line-height: line-height(s);
  }

  &.arrowlink-back {
    padding: 0 0 0 spacing(24);
    .arrowlink__arrow {
      right: auto;
      left: 0;
    }
  }
}
