@import 'resources/scss/_foundation.scss';
.promotions {
  .col-m-twelve {
    margin: spacing(6) 0 !important;
  }
  .promotions__category-title {
    padding: spacing(72) 0 spacing(18);
    @media only screen and (max-width: breakpoint(tabletSD)) {
      padding: spacing(42) 0 spacing(12);
    }
    h5 {
      padding: 0;
      margin: 0;
      line-height: line-height();
    }
    .arrowlink {
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
    }
  }
}
