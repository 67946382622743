@import 'resources/scss/_foundation.scss';
.rounded {
  border-radius: border-radius();

  &.rounded-m {
    border-radius: border-radius(m);
  }

  &.rounded-b {
    border-radius: border-radius(b);
  }
}
