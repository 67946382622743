@import 'resources/scss/_foundation.scss';
.promotion-card {
  background-color: color(white);
  box-shadow: shadow(2);
  border-radius: border-radius();
  border: 3px solid color(white);
  text-align: center;
  position: relative;
  width: 100%;

  transition: transition();
  .promotion-card__link {
    position: absolute;
    display: block;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
  }

  .promotion-card__content {
    padding: spacing(60) spacing(30);
    h3.promotion-card__model {
      color: color(black, light-1);
      transition: transition();
    }
    h5.promotion-card__color {
      margin-bottom: 0;
      margin-top: 0;
      opacity: opacity(24);
    }
  }

  .promotion-card__units-info {
    cursor: pointer;
  }

  .promotion-card__image {
    padding: spacing(18) spacing(12) 0;
    opacity: 0.9;
    transition: transition();
    height: 140px;
    max-width: 260px;
    margin: 0 auto;
    img {
      display: block;
      max-width: 80%;
      max-height: 100%;
      width: auto;
      height: auto;
      margin: 0 auto;
    }
  }
  .promotion-card__action {
    width: 100%;
    padding: spacing(18) spacing(30);
    background-color: color(white, dark-1);
    text-align: left;
    transition: transition();
    color: color(black, light-2);
    border-radius: 0 0 border-radius() border-radius();
    position: relative;
    font-size: font-size(s);
    line-height: line-height(s);
    &:before {
      border-right: 2px solid color(black, light-2);
      content: '';
      position: absolute;
      top: spacing(24)-1;
      right: spacing(30);
      transform: rotate(-45deg);
      height: spacing(9);
    }
    &:after {
      border-right: 2px solid color(black, light-2);
      content: '';
      position: absolute;
      top: spacing(30)-1;
      right: spacing(30);
      transform: rotate(45deg);
      height: spacing(9);
    }
  }

  .promotion-card__discount {
    font-size: font-size(s);
    line-height: line-height(s);
    color: color(black, light-2);
    .promotion-card__discount-message__price {
      font-weight: 700;
      color: color(ui-green);
      white-space: nowrap;
    }
  }

  &:hover {
    box-shadow: shadow(4);
    .promotion-card__action {
      background-color: color(orange, light-5);
      color: color(orange, dark-1);
      &:before {
        border-right: 2px solid color(orange, dark-2);
      }
      &:after {
        border-right: 2px solid color(orange, dark-2);
      }
    }
    .promotion-card__image {
      opacity: 1;
    }
    .promotion-card__content {
      h3.promotion-card__model {
        color: color(black);
      }
    }
  }

  &.promotion-card-small {
    .promotion-card__content {
      padding: spacing(30) spacing(18);
      .promotion-card__brand {
        opacity: opacity(24);
        margin-bottom: 0;
      }
      h3.promotion-card__model {
        font-size: heading-font-size(h4);
        min-height: spacing(60);
      }

      .promotion-card__image {
        padding-top: 0;
      }
      .promotion-card__discount {
        padding: 0 spacing(30);
      }
    }
    .promotion-card__action {
      padding: spacing(9) spacing(12);
      font-size: font-size(xs);
      line-height: line-height(xs);
      &:before {
        top: spacing(18)-4;
        height: spacing(6);
        right: spacing(18);
      }
      &:after {
        top: spacing(24)-6;
        height: spacing(6);
        right: spacing(18);
      }
    }
  }
  @media only screen and (max-width: 1154px) and (min-width: breakpoint(tabletSD)) {
    .promotion-card__content {
    }
    .promotion-card__image {
      height: spacing(96) !important;
    }
    .promotion-card__action {
      padding-top: spacing(6) !important;
      padding-bottom: spacing(6) !important;
      &:before {
        top: spacing(12) !important;
      }
      &:after {
        top: spacing(12) + 5 !important;
      }
    }
    &:not(.offer-group-card) {
      text-align: left;
      overflow: hidden;
      max-width: 600px;
      margin: 0 auto;
      &.promotion-card-small {
        .promotion-card__content {
          padding: spacing(18) spacing(24);
          .promotion-card__discount {
            padding: 0;
          }
        }
        .promotion-card__action {
          &:before {
            right: 0 !important;
          }
          &:after {
            right: 0 !important;
          }
        }
      }
      .promotion-card__content {
        padding: spacing(18) spacing(24);

        h3.promotion-card__model {
          padding-left: 25%;
          font-size: heading-font-size(h4);
          margin-bottom: spacing(6);
          padding-right: spacing(12);
        }
        h5.promotion-card__brand {
          padding-left: 25%;
          margin-bottom: 0;
          margin-top: spacing(6);
          opacity: opacity(24);
        }
        h5.promotion-card__color {
          display: none;
        }
        .promotion-card__image {
          width: 20%;
          padding: 0;
          position: absolute;
          top: spacing(24);
          left: spacing(12);
          overflow: hidden;
          img {
            width: spacing(108);
            margin-left: -spacing(6);
          }
        }
        .promotion-card__discount {
          position: absolute;
          font-size: 0;
          line-height: 0;
          top: spacing(6);
          left: spacing(6);
          .promotion-card__discount-message__price {
            font-size: font-size(xs);
            line-height: line-height(xs);
          }
        }
      }
      .promotion-card__action {
        position: absolute;
        font-size: 0;
        right: spacing(18);
        top: calc(50% - 18px);
        background-color: transparent;
        .promotion-card__units-info {
          font-size: 0;
        }
        &:before {
          top: spacing(18)-4;
          height: spacing(6);
          right: 0;
        }
        &:after {
          top: spacing(24)-6;
          height: spacing(6);
          right: 0;
        }
      }
      &:hover {
        .promotion-card__action {
          background-color: transparent;
        }
      }
    }
  }
  @media only screen and (max-width: breakpoint(tabletSD)) {
    text-align: left;
    overflow: hidden;
    max-width: 600px;
    margin: 0 auto;
    &.promotion-card-small {
      .promotion-card__content {
        padding: spacing(18) spacing(24);
        .promotion-card__discount {
          padding: 0;
        }
      }
      .promotion-card__action {
        &:before {
          right: 0 !important;
        }
        &:after {
          right: 0 !important;
        }
      }
    }
    .promotion-card__content {
      padding: spacing(18) spacing(24);

      h3.promotion-card__model {
        padding-left: 25%;
        font-size: heading-font-size(h4);
        margin-bottom: spacing(6);
        padding-right: spacing(12);
      }
      h5.promotion-card__brand {
        padding-left: 25%;
        margin-bottom: 0;
        margin-top: spacing(6);
        opacity: opacity(24);
      }
      h5.promotion-card__color {
        display: none;
      }
      .promotion-card__image {
        width: 20%;
        padding: 0;
        position: absolute;
        top: spacing(24);
        left: spacing(12);
        overflow: hidden;
        img {
          width: spacing(108);
          margin-left: -spacing(6);
        }
      }
      .promotion-card__discount {
        position: absolute;
        font-size: 0;
        line-height: 0;
        top: spacing(6);
        left: spacing(6);
        .promotion-card__discount-message__price {
          font-size: font-size(xs);
          line-height: line-height(xs);
        }
      }
    }
    .promotion-card__action {
      position: absolute;
      font-size: 0;
      right: spacing(18);
      top: calc(50% - 18px);
      background-color: transparent;
      .promotion-card__units-info {
        font-size: 0;
      }
      &:before {
        top: spacing(18)-4;
        height: spacing(6);
        right: 0;
      }
      &:after {
        top: spacing(24)-6;
        height: spacing(6);
        right: 0;
      }
    }
    &:hover {
      .promotion-card__action {
        background-color: transparent;
      }
    }
  }
}
