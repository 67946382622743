@import 'resources/scss/_foundation.scss';
.gradient {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
    opacity: opacity(80);
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.24) 20%,
      rgba(255, 255, 255, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3dffffff', endColorstr='#ffffff',GradientType=1 );
    mix-blend-mode: overlay;
  }
  &.yellow,
  &.blue,
  &.green {
    &:before {
      opacity: opacity(50);
    }
  }
}
