@import 'resources/scss/_foundation.scss';
.flow-navigation {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 8888;

  background-color: color(white);
  box-shadow: shadow(3, $inverted: true);
  .flow-navigation__content {
    padding: spacing(12);
    text-align: center;
    position: relative;
  }
  &.flow-navigation-progress-bar {
  }

  //Mobile
  @media only screen and (max-width: breakpoint(tabletSD)) {
    .flow-navigation__content {
      padding: spacing(6);
      text-align: right;
    }
  }
}
