@import 'resources/scss/_foundation.scss';
//Headings
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: font-family();
  font-weight: 700;
  margin-top: 0;
  padding-top: 0;
  &.fluid {
    max-width: 100% !important;
  }
  &.heading-adjust {
    text-indent: -0.5em;
  }
}

h1,
.h1 {
  font-size: heading-font-size(h1);
  line-height: heading-line-height(h1);
  margin: heading-line-height(h2) 0 calc(heading-line-height(h2) / 2);
  letter-spacing: -0.025em;
  max-width: 900px;
  &:first-child {
    margin-top: 0;
  }

  //Mobile
  @media only screen and (max-width: breakpoint(tabletSD)) {
    font-size: heading-font-size(h2);
    line-height: heading-line-height(h2);
    margin: heading-line-height(h2) 0 calc(heading-line-height(h2) / 2);
    letter-spacing: -0.01em;
    max-width: 780px;
  }
}

h2,
.h2 {
  font-size: heading-font-size(h2);
  line-height: heading-line-height(h2);
  margin: heading-line-height(h2) 0 calc(heading-line-height(h2) / 2);
  letter-spacing: -0.01em;
  max-width: 780px;
  &:first-child {
    margin-top: 0;
  }
  //Mobile
  @media only screen and (max-width: breakpoint(tabletSD)) {
    font-size: heading-font-size(h3);
    line-height: heading-line-height(h3);
    margin: heading-line-height(h3) 0 calc(heading-line-height(h3) / 2);
    max-width: 720px;
  }
}

h3,
.h3 {
  font-size: heading-font-size(h3);
  line-height: heading-line-height(h3);
  margin: heading-line-height(h3) 0 calc(heading-line-height(h3) / 2);
  max-width: 720px;
  &:first-child {
    margin-top: 0;
  }
  & + h1,
  & + h2,
  & + .h1,
  & + .h2 {
    margin-top: 0;
  }
  //Mobile
  @media only screen and (max-width: breakpoint(tabletSD)) {
    font-size: heading-font-size(h4);
    line-height: heading-line-height(h4);
    margin: heading-line-height(h4) 0 calc(heading-line-height(h4) / 2);
    max-width: 540px;
  }
}

h4,
.h4 {
  font-size: heading-font-size(h4);
  line-height: heading-line-height(h4);
  margin: heading-line-height(h4) 0 calc(heading-line-height(h4) / 2);
  max-width: 540px;
  &:first-child {
    margin-top: 0;
  }
  //Mobile
  @media only screen and (max-width: breakpoint(tabletSD)) {
    font-size: heading-font-size(h5);
    line-height: heading-line-height(h5);
    text-transform: uppercase;
    letter-spacing: 0.05em;
    max-width: 540px;
    margin: heading-line-height(h4) 0 calc(heading-line-height(h5) / 2);
  }
}

h5,
.h5 {
  font-size: heading-font-size(h5);
  line-height: heading-line-height(h5);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 400;
  max-width: 540px;
  opacity: opacity(50);
  margin: heading-line-height(h4) 0 calc(heading-line-height(h5) / 2);
  &:first-child {
    margin-top: 0;
  }
  & + h1,
  & + h2,
  & + h3,
  & + .h1,
  & + .h2,
  & + .h3 {
    margin-top: 0;
  }
  //Mobile
  @media only screen and (max-width: breakpoint(tabletSD)) {
    opacity: opacity(50);
  }
}

.hanging-punctuation {
  text-indent: -0.3em;
}
.no-wrap {
  word-break: keep-all;
  white-space: nowrap;
}
