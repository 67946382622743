@import 'resources/scss/_foundation.scss';

.toggle-button {
  display: inline-block;
  .toggle-button__title {
    color: color(black, light-2);
    transition: transition();
  }
  .toggle-button__button {
    width: spacing(78);
    height: spacing(42);
    border: 1px solid color(black, light-4);
    background-color: color(white, dark-1);
    border-radius: 21px;
    position: relative;
    cursor: pointer;
    box-shadow: inset 0 0 12px rgbaColor(color(black), 0.1);
    transition: transition();
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid color(white);
      border-radius: 21px;
      transition: transition();
    }
    &:before {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      width: spacing(36)-2;
      height: spacing(36)-2;
      transition: transition();
      background-color: color(white);
      border: 3px solid color(white, dark-1);
      border-radius: spacing(18)-1;
      box-shadow: shadow(2);
    }
    &:hover {
      &:before {
        box-shadow: shadow(4);
        border: 1px solid color(black, light-4);
      }
    }
  }
  &.toggle-button-on {
    .toggle-button__button {
      background-color: color(ui-green);
      &:before {
        left: spacing(36) + 2;
      }
    }
    &.toggle-button-small {
      .toggle-button__button {
        &:before {
          left: spacing(24) + 2;
        }
      }
    }
    .toggle-button__title {
      color: color(black, light-1);
    }
  }

  &.toggle-button-small {
    .toggle-button__title {
      display: inline;
      height: spacing(24);
      line-height: spacing(24);
      vertical-align: top;
      margin-right: spacing(6);
      h5 {
        line-height: spacing(24);
        padding: 0;
        margin: 0;
      }
    }
    .toggle-button__button {
      width: spacing(48);
      height: spacing(24);
      transition: transition();
      &:before {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: spacing(18)-2;
        height: spacing(18)-2;
        transition: transition();
        border-radius: spacing(6) + 3;
        box-shadow: shadow(1);
      }
      &:hover {
        &:before {
          box-shadow: shadow(3);
        }
      }
    }
  }
}
