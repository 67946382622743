@import 'resources/scss/_foundation.scss';
.models {
  .section.models__models-list {
    padding-top: spacing(240) !important;
    @media only screen and (max-width: breakpoint(tabletSD)) {
      padding-top: spacing(120) !important;
    }
  }
  .models__offers {
    @media only screen and (max-width: 1154px) {
      text-align: center;
      h2,
      h3,
      h1 {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .col-m-twelve {
    margin: spacing(6) 0 !important;
  }
}
