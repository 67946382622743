@import 'resources/scss/_foundation.scss';
.contact {
  .contact__hero__tabs {
    position: absolute !important;
    top: spacing(30);
    left: 0;
  }
  .contact__section {
    padding-bottom: spacing(42);
    margin-bottom: spacing(12);
    border-bottom: 1px solid color(black, light-3);
  }
  .contact__info {
    //Mobile
    @media only screen and (max-width: breakpoint(tabletSD)) {
      .col-m-twelve {
        margin: spacing(6) 0;
      }
    }
    .contact__phone {
      max-width: 90%;
      padding-top: spacing(18) - 3;
      padding-bottom: spacing(12);

      & + .contact__phone {
        padding-top: spacing(12);
      }
      &.contact__phone-big {
        h4 {
          margin-bottom: spacing(6);
        }
        h3 {
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      .contact__phone__name {
        display: inline-block;
        color: color(black, light-1);
        font-size: font-size(s);
        line-height: line-height(s);
      }
      .contact__phone__number {
        display: inline-block;
        font-weight: 700;
        margin-left: spacing(12);
        font-size: font-size(s);
        line-height: line-height(s);
      }
      //Mobile
      @media only screen and (max-width: breakpoint(tabletSD)) {
        max-width: 100%;
        .contact__phone__name,
        .contact__phone__number {
          display: block;
          text-align: left;
          margin-left: 0;
        }
      }
    }
    .contact__hours {
      padding-top: spacing(18) - 3;
      padding-bottom: spacing(12);
      & + .contact__hours {
        padding-top: spacing(12);
      }
      //Mobile
      @media only screen and (max-width: breakpoint(tabletSD)) {
        br {
          display: none;
        }
      }
    }
  }
}
