@import 'resources/scss/_foundation.scss';
.summary-card {
  position: relative;

  border-radius: border-radius();
  background-color: color(white);
  transition: transition();
  margin-bottom: spacing(24);
  overflow: hidden;
  box-shadow: shadow(3), shadow(0, 1px);

  .card__salesmen {
    padding: spacing(36);
    background-color: color(white, dark-1);

    text-align: left;
    border-top: 1px solid color(white, dark-2);

    .p-s {
      padding: 0;
    }

    .card__details__salesmen-salesman {
      margin-bottom: calc(line-height() / 3);
      line-height: 20px;

      img {
        margin-right: 0.5em;
        position: relative;
        top: 3px;
      }
    }
  }

  .summary-card__details {
    padding: spacing(30);
    .summary-card__details__info {
      width: 50%;
      vertical-align: top;
      display: inline-block;
      padding-right: spacing(36);
      text-align: left;
      p {
        padding-left: 0 !important;
      }
      //Mobile
      @media only screen and (max-width: breakpoint(mobileHD)) {
        padding-right: 0;
        p {
          br {
            display: none;
          }
        }
      }
    }
    .summary-card__details__price {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      padding-left: spacing(36);
      border-left: 1px solid color(black, light-4);
    }
  }
  .summary-card__header {
    padding: spacing(30);
    background-size: cover;
    background-position: center;
    position: relative;
    text-align: left;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.9;
      z-index: 1;
      content: '';
      background: linear-gradient(
        to right,
        rgbaColor(color(white, dark-1), 1) 40%,
        rgbaColor(color(white, dark-1), 0.9) 100%
      );
    }
    h3,
    h5 {
      position: relative;
      z-index: 10;
      margin-top: 0;
    }
    .summary-card__header__model {
      display: inline-block;
      width: 50%;
      margin-bottom: 0;
      text-transform: capitalize;
    }
    .summary-card__header__price {
      display: inline-block;
      width: 50%;
      text-align: right;
      margin-bottom: 0;
    }
  }
  .summary-card__actions {
    padding: spacing(12);
    background-color: color(white, dark-1);
    text-align: center;
    position: relative;
  }

  &.summary-card-dark {
    .summary-card__header {
      h3,
      h5 {
        color: color(white);
      }
      &:before {
        background: linear-gradient(
          to right,
          rgbaColor(color(black), 1) 40%,
          rgbaColor(color(black), 0.5) 100%
        );
      }
    }
  }

  &.summary-card-full {
    .summary-card__header {
      padding: spacing(42);
    }
    .summary-card__details {
      padding: spacing(42);
    }
  }

  //Mobile
  @media only screen and (max-width: breakpoint(mobileHD)) {
    &.summary-card-full {
      .summary-card__header {
        padding: spacing(24);
      }
      .card__salesmen {
        padding: spacing(24);
      }
      .summary-card__details {
        padding: spacing(24);
        .summary-card__details__info {
          width: 100%;
        }
        .summary-card__details__price {
          width: 100%;

          padding-left: 0;
          border-top: 1px solid color(black, light-4);
          border-left: none;
          margin-top: spacing(24);
          padding-top: spacing(30);
        }
      }
    }
  }
}
