@import 'resources/scss/_foundation.scss';
.mobile-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: spacing(60);
  border-bottom: 1px solid color(white, dark-2);
  background-color: color(white);
  z-index: 9999;
  .mobile-header__title {
    line-height: spacing(60);
    text-align: center;
    width: 100%;
    font-size: font-size(xs);
    text-transform: uppercase;
    font-weight: 700;
  }
  .mobile-header__link {
    line-height: spacing(60);
    width: spacing(60);
    position: absolute;
    right: 0;
    top: 0;
  }
  .mobile-header__info {
    position: absolute;
    right: spacing(18);
    top: spacing(18);
    border: 2px solid color(orange);
    width: spacing(24);
    height: spacing(24);
    border-radius: spacing(12);
    font-size: 0;
    &:before {
      content: '';
      position: absolute;
      width: 2px;
      height: spacing(6);
      top: spacing(9);
      left: spacing(9);
      background-color: color(orange);
    }
    &:after {
      content: '';
      position: absolute;
      width: 2px;
      height: 2px;
      top: spacing(6);
      left: spacing(9);
      background-color: color(orange);
    }
  }
  &.mobile-header-dark {
    border-bottom: none;
    background-color: color(black);
    color: color(white); 
    &.mobile-header-close {
      .mobile-header__link {
        &:before, &:after {
          border-right: 2px solid color(white) !important;
        }
      }
    }
    &.mobile-header-back {
      .mobile-header__link {
        &:before, &:after {
          border-right: 2px solid color(white) !important;
        }
      }
    }
  }
  &.mobile-header-close {
    .mobile-header__link {
      font-size: 0;
      &:before {
        border-right: 2px solid color(black);
        content: '';
        position: absolute;
        top: spacing(24) - 1;
        left: spacing(30);
        transform: rotate(45deg);
        height: spacing(12);
      }
      &:after {
        border-right: 2px solid color(black);
        content: '';
        position: absolute;
        top: spacing(24) - 1;
        left: spacing(30);
        transform: rotate(-45deg);
        height: spacing(12);
      }
    }
  }
  &.mobile-header-back {
    .mobile-header__link {
      right: auto;
      left: 0;
      font-size: 0;
      top: spacing(6);
      &:before {
        border-right: 2px solid color(black);
        content: '';
        position: absolute;
        top: spacing(18) - 1;
        left: spacing(30);
        transform: rotate(45deg);
        height: spacing(9);
      }
      &:after {
        border-right: 2px solid color(black);
        content: '';
        position: absolute;
        top: spacing(24) - 1;
        left: spacing(30);
        transform: rotate(-45deg);
        height: spacing(9);
      }
    }
  }
  &.mobile-header-no-title {
    border-bottom: none;
    background-color: transparent;
  }
}
