@import 'resources/scss/_foundation.scss';
.more-navigation {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: color(white, dark-1);
  .covid-message {
    background-color: color(yellow);
    font-size: font-size(s);
    line-height: line-height(s);
    text-align: center;
    padding: spacing(6) spacing(24);
    a {
      color: color(black);
      font-weight: 700;
    }
  }
}
