@import 'resources/scss/_foundation.scss';
.select-card {
  text-align: center;
  position: relative;
  padding: spacing(30);
  background-color: color(white);
  transition: transition();
  width: 100%;
  border-radius: border-radius();
  margin: spacing(6);
  overflow: hidden;
  box-shadow: shadow(0, 1px);
  display: inline-block;
  max-width: spacing(180);
  transition: transition();
  border: 1px solid color(white);
  cursor: pointer;
  &.select-card-full {
    max-width: 100%;
    text-align: left;
  }
  &.select-card-disabled {
    box-shadow: none;
    background-color: color(white, dark-2);
    border-color: color(white, dark-2);
    .select-card__text,
    .select-card__help-text {
      color: color(black, light-2);
    }
  }
  .select-card__text {
    padding: 0;
    margin: 0 !important;
    font-size: font-size(s);
    line-height: line-height(s);
  }
  .select-card__help-text {
    font-size: font-size(xs);
    color: color(black, light-2);
    padding: 0;
    margin: 0;
  }
  .select-card__link {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
  }
  .select-card__check {
    position: absolute;
    top: spacing(12);
    right: spacing(12);
    opacity: 0;
    transition: transition();
  }
  .select-card__image {
    width: 100%;
    padding: 0 spacing(18) spacing(18);
    height: spacing(84);
    line-height: spacing(60);
    img {
      width: 100%;
      vertical-align: middle;
    }
  }

  //Mobile
  @media only screen and (max-width: breakpoint(tabletSD)) {
    max-width: 100%;
    padding: spacing(18) spacing(24);
    margin: spacing(6) 0;
    .select-card__text {
      display: none;
    }
    &.select-card-full {
      .select-card__text {
        text-align: left;
        display: block;
      }
      .select-card__help-text {
        text-align: left;
      }
    }
    .select-card__help-text {
      text-align: right;
    }
    .select-card__check {
      top: spacing(9);
      right: spacing(9);
    }
    .select-card__image {
      width: spacing(48);
      padding: 0;
      position: absolute;
      top: spacing(3);
      left: spacing(18);
    }
  }

  &:hover {
    box-shadow: shadow(3), shadow(0, 1px);
  }

  &.select-card-active {
    border: 1px solid color(ui-green);
    .select-card__check {
      opacity: 1;
      top: spacing(6);
    }
  }
}
