@import 'resources/scss/_foundation.scss';
.plan-card {
  text-align: center;
  position: relative;
  padding: spacing(30) 0 spacing(36);
  background-color: color(white);
  transition: transition();
  width: 100%;
  border-radius: border-radius();
  margin-bottom: spacing(24);
  .plan-card__nounits {
    color: color(black, light-3);
    font-size: font-size(s);
    line-height: line-height(s);
    transition: transition();
  }
  .plan-card__link {
    position: absolute;
    display: block;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
  }
  .plan-card__name {
    margin: 0;
    padding: 0;
    transition: transition();
    color: color(black, light-1);
    text-transform: capitalize;
  }
  .plan-card__image {
    padding: spacing(18) spacing(12) 0;
    opacity: 0.9;
    transition: transition();
    height: 160px;
    img {
      display: block;
      max-width: 80%;
      max-height: 160px;
      width: auto;
      height: auto;
      margin: 0 auto;
    }
  }
  .plan-card__details {
    font-size: font-size();
    line-height: line-height();
    color: color(black, light-1);
    transition: transition();
    position: relative;
    z-index: 50;
    max-width: 300px;
    margin: spacing(24) auto;

    .plan-card__detail {
      padding: spacing(6) 0;
      display: flex;
      border-top: 1px solid color(white, dark-2);
      .plan-card__detail__name {
        flex-grow: 1;
        text-align: left;
      }
      .plan-card__detail__value {
        flex-grow: 1;
        font-weight: 700;
        text-align: right;
        &.plan-card__detail__value-text {
          text-align: center;
          font-weight: normal;
          padding-top: spacing(12);
          font-size: font-size(s);
          line-height: line-height(s);
        }
      }
    }
  }
  .plan-card__arrowlink {
    opacity: 0;
    transition: transition();
    font-size: font-size(s);
    line-height: line-height(s);
    color: color(orange);
  }

  &:hover {
    box-shadow: shadow(4), shadow(0, 1px);
    .plan-card__image {
      opacity: 1;
    }
    .plan-card__name {
      color: color(black);
    }
    .plan-card__price {
      color: color(black);
    }
    .plan-card__arrowlink {
      opacity: 1;
    }
  }

  //Mobile
  @media only screen and (max-width: breakpoint(mobileHD)) {
    box-shadow: shadow(4), shadow(0, 1px);
    margin-bottom: 0;
    margin-top: spacing(6);
    padding: spacing(18) 0 spacing(24);
    .plan-card__image {
      opacity: 1;
    }
    .plan-card__name {
      color: color(black);
      padding-left: spacing(24);
      padding-right: spacing(24);
    }
    .plan-card__code {
      padding-left: spacing(48);
      padding-right: spacing(48);
    }
    .plan-card__price {
      color: color(black);
    }
    .plan-card__arrowlink {
      opacity: 1;
    }
    .plan-card__details {
      min-width: 100%;
      .plan-card__detail {
        padding-left: spacing(24);
        padding-right: spacing(24);
      }
    }
  }

  &.plan-card-disabled {
    .plan-card__image {
      opacity: opacity(24);
      img {
        filter: grayscale(100);
      }
    }
    .plan-card__price {
      color: color(black, light-3);
    }
    .plan-card__name {
      color: color(black, light-2);
    }
    .plan-card__characteristics {
      color: color(black, light-3);
    }
  }
}
