@import 'resources/scss/_foundation.scss';
.show-prices {
  .show-prices__content {
    padding-top: spacing(180) !important;
  }
  .show-prices__fieldset {
    padding: spacing(30) 0;
    margin: spacing(30) 0 spacing(24);
    border-bottom: 1px solid color(white, dark-2);
    h4 {
      margin-bottom: spacing(24);
      color: color(black, light-1);
    }
  }
  .show-prices__action {
    margin: spacing(30) 0;

    position: relative;
  }
  .show-prices__decidir-logo {
    text-align: center;
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobileHD)) {
    .show-prices__content {
      padding-top: spacing(108) !important;
      padding-bottom: spacing(30) !important;
      text-align: center;
    }
    .show-prices__fieldset {
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
