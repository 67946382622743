@import 'resources/scss/_foundation.scss';

.loader {
  display: block;
  text-align: center;
  padding: spacing(24);

  .loader__spinner {
    position: relative;
    left: 50%;
    margin-left: 0 - spacing(24);

    border-radius: 50%;
    width: spacing(48);
    height: spacing(48);
    border: spacing(3) solid color(orange, light-3);
    border-top-color: color(orange);
    animation: spin 1s infinite linear;
  }
  &.loader-small {
    padding: spacing(12);
    .loader__spinner {
      margin-left: -spacing(12);
      width: spacing(24);
      height: spacing(24);
    }
  }
  &.loader-red {
    .loader__spinner {
      border: spacing(3) solid color(red, light-3);
      border-top-color: color(red);
    }
  }

  &.loader-yellow {
    .loader__spinner {
      border: spacing(3) solid color(yellow, light-3);
      border-top-color: color(yellow);
    }
  }
  &.loader-blue {
    .loader__spinner {
      border: spacing(3) solid color(blue, light-3);
      border-top-color: color(blue);
    }
  }

  .loader__message {
    max-width: 300px;
    margin-top: spacing(12);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    h5 {
      text-align: center;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
