@import 'resources/scss/_foundation.scss';
.balance-information {
  position: fixed;
  top: 0;
  right: 0;
  background-color: color(black);
  color: color(white, dark-2);
  z-index: 9988;
  padding: spacing(12);
  font-size: font-size(s);
  line-height: line-height(s);
  box-shadow: shadow(4);
  border-radius: 0 0 0 spacing(6);
  min-width: 300px;
  .balance-information__row {
    display: flex;
    .balance-information__row__user {
      font-size: font-size(xs);
      line-height: line-height(xs);
      opacity: 0.5;
      margin-bottom: spacing(6);
    }
    .balance-information__column {
      width: 50%;
      &:last-child {
        text-align: right;
        font-weight: 700;
      }
    }
  }
  .balance-information__balance {
    display: inline-block;
    padding: 0 spacing(6);
    border-radius: border-radius();
    &.balance-information__balance-negative {
      background-color: color(ui-red);
    }
    &.balance-information__balance-positive {
      background-color: color(ui-green);
    }
  }
  //Mobile
  @media only screen and (max-width: breakpoint(tabletSD)) {
    position: relative;
    margin: 0 spacing(6);
    border-radius: 0 0 spacing(6) spacing(6);
    .balance-information-notmobile {
      display: none;
    }
  }
}
