@import 'resources/scss/_foundation.scss';
.header {
  background-color: color(white);
  height: spacing(108);
  &.header-covid {
    height: spacing(108) + spacing(36);
  }
  .covid-message {
    background-color: color(yellow);
    font-size: font-size(s);
    line-height: line-height(s);
    text-align: center;
    padding: spacing(6) spacing(24);
    a {
      color: color(black);
      font-weight: 700;
    }
  }
  .header__info {
    font-size: font-size(xs) !important;
    line-height: line-height(xs);
    text-align: right;
    color: color(black, light-1);
    padding-top: spacing(30);
    .header__info__text {
      display: inline-block;
      margin-right: spacing(6);
    }
    .header__info__social {
      display: inline-block;
      margin-left: spacing(6);
      a {
        position: relative;
        top: 3px;
        transition: transition();
        &:hover {
          opacity: 0.75;
        }
      }
      &.header__info__social-active {
        a {
          opacity: 0.25;
        }
      }
    }
  }
  .header__logo {
    display: inline-block;
    margin-top: spacing(42)-3px;
    margin-right: spacing(18);
  }
  .header__navigation {
    margin-top: spacing(42)-2px;
    vertical-align: top;
    display: inline-block;
  }
  .header__navigation__link {
    display: inline-block;
    vertical-align: top;
    padding: 0 spacing(12);
    &.header__navigation__link-active {
      a {
        color: color(orange);

        &:hover {
          color: color(orange, dark-1);
        }
      }
    }
    a {
      text-decoration: none;
      font-weight: 700;
      color: color(black);
      transition: transition();
      &:hover {
        text-decoration: none;
        color: color(black, light-2);
      }
    }

    .header__navigation__link-menu {
      display: inline-block;
      position: relative;
      font-weight: 700;
      span {
        color: color(black);
        cursor: pointer;
        transition: transition();
        transition-delay: 0.1s;
      }
      &:hover {
        .header__navigation__link__dropdown {
          height: 162px;
          opacity: 1;
        }
        span {
          color: color(black, light-2);
        }
      }
      .header__navigation__link__dropdown {
        position: absolute;
        top: spacing(42);
        left: 0;
        background-color: color(white);
        z-index: 999;
        box-shadow: shadow(2, 2px, 0.08, true), shadow(1, 1px);
        width: 250px;
        border-radius: border-radius();
        overflow: hidden;
        height: 0;
        opacity: 0;
        transition: transition(fast);
        transition-delay: 0.2s;
        a {
          display: block;
          height: spacing(54);
          line-height: spacing(54);
          border-bottom: 1px solid color(white, dark-1);
          padding: 0 spacing(18);
          font-weight: normal;
          transition: transition(fast);
          &:hover {
            background-color: color(white, dark-1);
            color: color(black);
          }
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
  //Mobile
  @media only screen and (max-width: 1154px) {
    .header__logo {
      overflow: hidden;
      width: spacing(30);
    }
    .header__info {
      .header__info__text {
        display: none;
      }
    }
  }
}
