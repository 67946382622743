@import 'resources/scss/_foundation.scss';
.home {
  .covid-message {
    background-color: color(yellow);
    font-size: font-size(s);
    line-height: line-height(s);
    text-align: center;
    padding: spacing(6) spacing(24);
    a {
      color: color(black);
      font-weight: 700;
    }
  }
  .home__hero {
    padding-bottom: spacing(60);
    h1 {
      padding-top: spacing(24);
    }
  }
  .home__models {
    background-color: color(black);
    h2 {
      color: color(white);
    }
  }
  .home__models-mobile {
    background-color: color(black);
    padding-top: spacing(240) !important;
    padding-bottom: spacing(60) !important;
    h2 {
      color: color(white);
    }
  }
  .home__contact {
    padding-top: spacing(240) !important;
    position: relative;

    .home__contact__nav {
      position: absolute;
      top: 0 - spacing(48);
      left: 50%;
      & > div {
        transform: translateX(-50%);
      }
    }
    //Mobile
    @media only screen and (max-width: breakpoint(tabletSD)) {
      padding-top: spacing(120) !important;
      overflow: hidden;
      .home__contact__nav {
        top: spacing(24);
      }
    }
  }
  .home__contact__section {
    padding-bottom: spacing(42);
    margin-bottom: spacing(12);
    border-bottom: 1px solid color(black, light-3);
  }
  .home__contact__info {
    //Mobile
    @media only screen and (max-width: breakpoint(tabletSD)) {
      .col-m-twelve {
        margin: spacing(6) 0;
      }
    }
    .home__contact__phone {
      max-width: 90%;
      padding-top: spacing(18) - 3;
      padding-bottom: spacing(12);

      & + .home__contact__phone {
        padding-top: spacing(12);
      }
      &.home__contact__phone-big {
        h4 {
          margin-bottom: spacing(6);
        }
        h3 {
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      .home__contact__phone__name {
        display: inline-block;
        color: color(black, light-1);
        font-size: font-size(s);
        line-height: line-height(s);
      }
      .home__contact__phone__number {
        display: inline-block;
        font-weight: 700;
        margin-left: spacing(12);
        font-size: font-size(s);
        line-height: line-height(s);
      }
      //Mobile
      @media only screen and (max-width: breakpoint(tabletSD)) {
        max-width: 100%;
        .home__contact__phone__name,
        .home__contact__phone__number {
          display: block;
          text-align: left;
          margin-left: 0;
        }
      }
    }
    .home__contact__hours {
      padding-top: spacing(18) - 3;
      padding-bottom: spacing(12);
      & + .home__contact__hours {
        padding-top: spacing(12);
      }
      //Mobile
      @media only screen and (max-width: breakpoint(tabletSD)) {
        br {
          display: none;
        }
      }
    }
  }
  .home__services {
    position: relative;
    .home__services__image {
      position: absolute;
      height: 100%;
      top: 0;
      left: 50%;
      img {
        height: 100%;
      }
    }
  }
  .home__services-mobile {
    padding-top: spacing(240);
    h5 {
      max-width: 100%;
    }
    .home__services__image-mobile {
      position: absolute;
      width: 100%;
      max-width: 496px;
      overflow: hidden;
      height: spacing(180);
      top: spacing(60);
      left: 50%;
      transform: translateX(-50%);
      text-align: center;

      img {
        width: 100%;
        min-width: 496px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 10%;
        background-color: red;
        z-index: 20;
        background: linear-gradient(
          -90deg,
          rgba(239, 238, 238, 0) 0%,
          rgba(239, 238, 238, 1) 80%
        );
      }
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 10%;
        background-color: red;
        z-index: 20;
        background: linear-gradient(
          90deg,
          rgba(239, 238, 238, 0) 0%,
          rgba(239, 238, 238, 1) 80%
        );
      }
    }
  }
  .home__contact__hours {
    padding-top: spacing(18) - 3;
    padding-bottom: spacing(12);
    & + .home__contact__hours {
      padding-top: spacing(12);
    }
    //Mobile
    @media only screen and (max-width: breakpoint(tabletSD)) {
      br {
        display: none;
      }
    }
  }
  .home__company {
    position: relative;
    .home__company__image {
      position: relative;
      padding: spacing(60) 0;
      height: spacing(240);
      text-align: center;
      .home__company__image__background {
        position: absolute;
        width: 100%;
        left: 0;
        img {
          width: 800px;
        }
      }
      .home__company__image__logo {
        position: absolute;
        width: 100%;
        left: 0;
        top: spacing(18);
        img {
          height: spacing(120);
          box-shadow: 0 0px 120px 40px color(white, dark-1);
          background-color: color(white, dark-1);
        }
      }
      //Mobile
      @media only screen and (max-width: breakpoint(tabletSD)) {
        height: spacing(180);
        .home__company__image__background {
          display: none;
        }
      }
    }
  }
  .home__jobs {
    .home__jobs__form {
      padding-top: spacing(42);
      text-align: center;
    }
  }
}
