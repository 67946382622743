@import 'resources/scss/_foundation.scss';
.news {
  .news__hero {
    padding-bottom: spacing(60) ;
  }
  .news__post {
    text-align: left !important;
    padding-bottom: spacing(120);
  }
  .news__list {
    
    .news__item {
      text-align: left;
      padding-bottom: spacing(60) ;
      //Mobile
      @media only screen and (max-width: breakpoint(tabletSD)) {
        padding-bottom: spacing(60) ;
      }
    }
  }
  .news__pages {
    padding-bottom: spacing(120) ;
    .pages {
      font-size: font-size(xs);
      text-transform: uppercase;
      .pages__page {
        padding: spacing(6) spacing(12);
        border-radius: spacing(6);
        margin: spacing(6);
        background-color: color(white, dark-1);
        text-decoration: none;
        color: color(black);
        border: 1px solid color(white, dark-2);
        display: inline-block;
        &.active {
          background-color: color(white);
          color: color(orange);
          pointer-events: none;
        }
        &:hover {
          background-color: color(white, dark-2);
          border: 1px solid color(black, light-2);
        }
      }
      .pages__prev, .pages__next  {
        padding: spacing(6) spacing(12);
        border-radius: spacing(6);
        margin: spacing(6);
        background-color: color(white, dark-1);
        text-decoration: none;
        color: color(black);
        border: 1px solid color(white, dark-2);
        display: inline-block;
        &:hover {
          background-color: color(white, dark-2);
          border: 1px solid color(black, light-2);
        }
      }
    }
  }
}
