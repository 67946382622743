@import 'resources/scss/_foundation.scss';
//tables
table,
.table {
  border: 1px solid color(black, light-3);
  background-color: color(white, dark-1);
  text-align: left;
  thead {
    background-color: color(black, light-4);
  }
  td,
  th {
    padding: spacing(6) spacing(18) spacing(6) spacing(12);
    vertical-align: top;
    border-right: 1px solid color(black, light-3);
    border-bottom: 1px solid color(black, light-3);
    &:last-child {
      border-right: none;
    }
  }
  tr {
    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}
