@import 'resources/scss/_foundation.scss';
.mobile-navigation {
  position: fixed;
  top: 0;
  width: 100%;
  height: spacing(78);
  background-color: color(white);
  z-index: 9999;
  box-shadow: shadow(3);
  padding: 0 spacing(12);

  .mobile-navigation__link {
    width: 20%;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    height: spacing(78);
    .mobile-navigation__button {
      position: absolute;
      z-index: 20;
      width: 100%;
      height: 100%;
      top: 0;
      padding-top: 44px;
      left: 0;
      font-size: 0.9rem;
      text-decoration: none;
      font-weight: bold;
      text-align: center;
      color: color(black);
      display: block;
    }
    .mobile-navigation__button--no-text {
      font-size: 0;
    }
    .mobile-navigation__link__image {
      position: absolute;
      top: spacing(6);
      left: 50%;

      margin-left: calc(-1 * spacing(42) / 2);
      &.mobile-navigation__link__active {
        z-index: 10;
        opacity: 0;
      }
      &.mobile-navigation__link__inactive {
        z-index: 1;
        opacity: 1;
      }
    }

    &.mobile-navigation__link-active {
      a {
        color: color(orange);
      }
      .mobile-navigation__link__image {
        &.mobile-navigation__link__active {
          opacity: 1;
        }
        &.mobile-navigation__link__inactive {
          opacity: 0;
        }
      }
    }
  }
}
