@import 'resources/scss/_foundation.scss';
.price-table {
  font-size: font-size(s);
  line-height: line-height(s);
  .price-table__table {
    padding-bottom: spacing(12);
  }
  .price-table__row {
    border-bottom: 1px solid color(white, dark-2);
    padding: spacing(6) 0;
    &:first-child {
      border-top: 1px solid color(white, dark-2);
    }
    .price-table__row__name {
      display: inline-block;
      width: 50%;
      text-transform: uppercase;
      color: color(black, light-2);
      font-size: font-size(xs);
      text-align: left;
    }
    .price-table__row__price {
      text-align: right;
      display: inline-block;
      width: 50%;
    }
  }

  &.price-table-small {
    .price-table__row {
      padding: 0 0 spacing(3) 0;
      border-bottom: none;
      &:first-child {
        border-top: none;
      }
      .price-table__row__name {
        text-transform: none;
      }
    }
  }
}
