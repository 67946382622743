@import 'resources/scss/_foundation.scss';
.version-card {
  text-align: center;
  position: relative;
  padding: spacing(30) 0 spacing(36);
  background-color: color(white);
  transition: transition();
  width: 100%;
  border-radius: border-radius();
  margin-bottom: spacing(24);
  .version-card__nounits {
    color: color(black, light-3);
    font-size: font-size(s);
    line-height: line-height(s);
    transition: transition();
  }
  .version-card__link {
    position: absolute;
    display: block;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
  }
  .version-card__version {
    margin: 0;
    padding: 0;
    transition: transition();
    color: color(black, light-1);
    text-transform: capitalize;
  }
  .version-card__image {
    padding: spacing(18) spacing(12) 0;
    opacity: 0.9;
    transition: transition();
    height: 160px;
    img {
      display: block;
      max-width: 80%;
      max-height: 160px;
      width: auto;
      height: auto;
      margin: 0 auto;
    }
  }
  .version-card__price {
    font-size: font-size(s);
    line-height: line-height(s);
    color: color(black, light-1);
    transition: transition();
    position: relative;
    z-index: 50;
    min-height: spacing(24);
  }
  .version-card__arrowlink {
    opacity: 0;
    transition: transition();
    font-size: font-size(s);
    line-height: line-height(s);
    color: color(orange);
  }
  .version-card__download-characteristic {
    opacity: 0;
    transition: transition();
    font-size: font-size(s);
    line-height: line-height(s);
    color: color(orange);
    z-index: 20;
    position: absolute;
    padding: spacing(3) spacing(6);
    width: 100%;
    bottom: 0;
    left: 0;
    border-top: 1px solid color(black, light-5);
    background-color: color(black, light-6);
  }
  .version-card__units-info {
    min-height: spacing(60);
  }
  &:hover {
    box-shadow: shadow(4), shadow(0, 1px);
    .version-card__image {
      opacity: 1;
    }
    .version-card__version {
      color: color(black);
    }
    .version-card__price {
      color: color(black);
    }
    .version-card__arrowlink,
    .version-card__download-characteristic {
      opacity: 1;
    }
  }

  //Mobile
  @media only screen and (max-width: breakpoint(mobileHD)) {
    box-shadow: shadow(4), shadow(0, 1px);
    margin-bottom: 0;
    margin-top: spacing(6);
    padding: spacing(18) 0 spacing(42);
    .version-card__image {
      opacity: 1;
    }
    .version-card__version {
      color: color(black);
    }
    .version-card__price {
      color: color(black);
    }
    .version-card__arrowlink,
    .version-card__download-characteristic {
      opacity: 1;
    }
  }

  &.version-card-disabled {
    padding: spacing(18) 0 spacing(60);
    .version-card__image {
      opacity: opacity(24);
      img {
        filter: grayscale(100);
      }
    }
    .version-card__price {
      color: color(black, light-3);
    }
    .version-card__version {
      color: color(black, light-2);
    }
    .version-card__characteristics {
      color: color(black, light-3);
    }
  }

  .version-card__characteristics {
    font-size: font-size(xs);
    line-height: line-height(xs);
    color: color(black, light-2);
    transition: transition();
    text-align: left;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: transition();
    .version-card__characteristic {
      padding: spacing(6) 0;
      border-bottom: 1px solid color(white, dark-2);
      margin: 0 spacing(24);
      &:first-child {
        border-top: 1px solid color(white, dark-2);
        margin-top: spacing(24);
      }
      &:last-child {
        margin-bottom: spacing(36);
      }
    }
  }

  &.version-card-open {
    padding: spacing(30) 0 spacing(30);
    &.version-card-disabled {
      padding: spacing(30) 0 spacing(60);
    }
    .version-card__characteristics {
      opacity: 1;
      max-height: 1000px;
    }
  }

  &.version-card-compare {
    box-shadow: none;
    margin-bottom: 0;
    margin-top: spacing(6);
    padding: 0;
    .version-card__characteristics {
      opacity: 1;
      max-height: none;
      margin-bottom: spacing(36);
      .version-card__characteristic {
        margin: 0;
      }
    }
    .version-card__image {
      display: none;
    }
    .version-card__version {
      display: none;
    }
    .version-card__price {
      display: none;
    }
    .version-card__arrowlink {
    }
    .version-card__model {
      display: none;
    }
  }
}
