@import 'resources/scss/_foundation.scss';
.stories-modal {
  position: relative;
  .stories__content {
    background-color: color(black);
    height: calc(100vh);
    .story-full {
      position: absolute;
      top: 0;
      left: 0;
      background-color: color(black);
      width: 100vw;
      height: 100%;
      
      .story-full-data {
        position: absolute;
        bottom: spacing(24);
        left: spacing(24);
        color: color(white);
        text-align: left;
        z-index: 10;
        display: flex;
        
        width: 600px;
        

        .story-full-data-content {
          padding-left: spacing(24);
          padding-top: spacing(12);
          h4, h5 {
            margin: 0;
            text-shadow: 0px 0 2px color(black);
            pointer-events: none;
            max-width: 100%;
          }
        }
        .story-full-data-actions {
          // position: absolute;
          // right: 0;
          // bottom: 0 - spacing(6);
        }
      }
      .story-full-video-preview {
        position: absolute;
        top: 0;
        left: 0;
        color: color(white);
        z-index: 5;
        video {
          width: 100vw;
          height: calc(100vh - 96px);
          object-fit: fit;
          overflow: hidden;
          object-position: center center;
          
        }
      
      }
    }
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobileHD)) {
    .stories__content {
      height: calc(100vh - 60px - 93px);
      position: fixed;
      top: spacing(60);
      .story-full {
        .story-full-data { 
          text-align: center;
          width: calc(100% - 48px);
          display: block;
          h4, h5 {
            margin: 0;
            text-shadow: 0px 0 2px color(black);
            pointer-events: none;
            max-width: 100%;
          }
        }
        .story-full-video-preview {
          video {
            height: calc(100vh - 60px );
            object-fit: cover;
          }
        }

      }
    }
  }
}
