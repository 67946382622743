@import 'resources/scss/_foundation.scss';
.flow-header {
  padding: spacing(48) spacing(24);
  .flow-header__brand,
  .flow-header__data {
    display: inline-block;
    vertical-align: top;
  }
  .flow-header__data {
    h4,
    h5,
    h6 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &.flow-header-center {
    text-align: center;
  }
  &.flow-header-small {
    padding: spacing(12) spacing(24);
    min-height: spacing(66);
    border-bottom: 1px solid color(black, light-4);
    height: spacing(60);
    line-height: spacing(60);
    .flow-header__brand {
      max-width: spacing(42);
      overflow: hidden;
      margin-top: spacing(6);
      img {
        vertical-align: top;
      }
    }
  }

  //Mobile
  @media only screen and (max-width: breakpoint(tabletSD)) {
    padding: spacing(24) spacing(24);
    &.flow-header-small {
      .flow-header__brand {
        margin-top: calc(spacing(6) / 2);
      }
    }
  }
}
