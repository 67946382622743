@import 'resources/scss/_foundation.scss';
.salesman {
  padding-top: spacing(12);
  padding-bottom: spacing(12);
  height: spacing(60);
  position: relative;
  border-top: 1px solid color(black, light-4);
  .salesman__avatar {
    display: inline-block;
    width: spacing(36);
    height: spacing(36);
    border-radius: spacing(18);
    background-color: color(orange, light-3);
    border: 2px solid color(black);
    margin-right: spacing(12);
    overflow: hidden;
    position: relative;
    .salesman__avatar__initials {
      position: absolute;
      top: 0;
      left: 0;
      width: spacing(36)- 2px;
      height: spacing(36)- 2px;
      text-align: center;
      font-size: font-size(s);
      letter-spacing: 0.05em;
      text-transform: uppercase;
      display: inline-block;
      line-height: spacing(36) - 2px;
      opacity: 0.5;
      z-index: 5;
    }
    .salesman__avatar__image {
      position: absolute;
      top: 0;
      left: 0;
      width: spacing(36);
      height: spacing(36);
      z-index: 10;
      img {
        width: spacing(36);
      }
    }
  }
  .salesman__name {
    display: inline-block;
    line-height: spacing(36);
    vertical-align: top;
  }
  .salesman__contact {
    display: inline-block;
    position: absolute;
    right: 0;
    img {
      width: spacing(36);
    }
    .salesman__whatsapp,
    .salesman__zoom {
      display: inline-block;
      margin-right: spacing(6);
    }
  }
}
