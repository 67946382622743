@import 'resources/scss/_foundation.scss';
.stories-block {
  background-color: color(black);
  padding: spacing(36) 0 spacing(12);
  text-align: center;
  color: color(white);
  a, a:hover {
    color: color(white);
  }
  h5 {
    margin: 0;
    width: 100%;
    max-width: 100%;
  }
  .stories-scroll {
    width: 100vw;
    
  overflow-x: scroll;
    .stories-container {
      
      white-space: nowrap;
      
      padding: spacing(24);
      .story {
        cursor: pointer;
        overflow: hidden;
        white-space: normal;
        width: 300px;
        height: 300px;
        text-align: left;
        display: inline-block;
        background-color: color(black, light-1);
        margin: spacing(12);
        border-radius: spacing(12);
        position: relative;
        border: 2px solid color(black);
        &:hover {
          border: 2px solid color(orange);
          .story-video-preview {
            opacity: 1;
            video { 
              filter: grayscale(0%);
            }
          }
          .story-data {
           
            h4, h5 {

             
            }
            .story-data-action {
              
            }
          }
        }
        &:last-child {
          margin-right: spacing(96);
        }
        .story-data {
          pointer-events: none;
          position: absolute;
          bottom: 0;
          left: 0;
          padding: spacing(18);
          width: 100%;
          z-index: 10;
          h4, h5 {
            margin: 0;
            text-shadow: 0px 0 2px color(black);
            pointer-events: none;
          }
          .story-data-action {
            display: none;
            pointer-events: all;
          }
        }
        .story-video-preview {
          position: absolute;
          bottom: -12px;
          left: 0;
          opacity: .3;
          width: 100%;
          z-index: 5;
          overflow: hidden;
          border-radius: spacing(12);
          video {
            width: 300px;
            height: 324px;
            object-fit: cover;
            overflow: hidden;
            border-radius: spacing(12);
            filter: grayscale(100%);
            
          }
        }
        //Mobile
        @media only screen and (max-width: breakpoint(tabletSD)) {
          height: 420px;
          width: 280px;
          margin: spacing(6);
          
          opacity: .8;
          &:hover {
            .story-data {
              .story-data-action {
                display: block;
                pointer-events: all;
              }
            }
          }
          .story-video-preview {
            top: 0;
            bottom: auto;
            border-radius: 0;
            video {
              width: 280px;
              height: 420px;
              filter: grayscale(0);
              border-radius: 0;
            }

          }
        }
      }
    }
  }
  

  
}
