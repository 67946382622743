@import 'resources/scss/_foundation.scss';
.mobile-tabs {
  padding: spacing(24) 0 0;
  overflow: hidden;
  box-shadow: 0 2px 0 color(white);
  background-color: color(white);
  position: relative;
  &.mobile-tabs-insection {
    background-color: color(white, dark-1);
    .mobile-tabs__container {
      padding-left: spacing(24);
      padding-right: spacing(24);
    }
    &:after {
      background: linear-gradient(
        to right,
        rgbaColor(color(white, dark-1), 1) 0%,
        rgbaColor(color(white, dark-1), 0) 100%
      );
    }
    &:before {
      background: linear-gradient(
        to left,
        rgbaColor(color(white, dark-1), 1) 0%,
        rgbaColor(color(white, dark-1), 0) 100%
      );
    }
  }
  &.mobile-tabs-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    box-shadow: shadow(1, 0, 0.08, true);
    border-top: 1px solid color(white, dark-2);
    background-color: color(white, dark-1);
    z-index: 9999;
    padding: spacing(12) 0 spacing(24);
    &:after {
      background: linear-gradient(
        to right,
        rgbaColor(color(white, dark-1), 1) 0%,
        rgbaColor(color(white, dark-1), 0) 100%
      );
    }
    &:before {
      background: linear-gradient(
        to left,
        rgbaColor(color(white, dark-1), 1) 0%,
        rgbaColor(color(white, dark-1), 0) 100%
      );
    }
    .mobile-tabs__container {
      border-bottom: none;
      box-shadow: none;
    }
    .mobile-tabs__title {
      text-align: center;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding-top: spacing(12);
    }
  }
  &:after {
    position: absolute;
    pointer-events: none;
    z-index: 10;
    top: 0;
    left: 0;
    width: spacing(18);
    height: 98%;
    background: linear-gradient(
      to right,
      rgbaColor(color(white), 1) 0%,
      rgbaColor(color(white), 0) 100%
    );
    content: '';
  }
  &:before {
    position: absolute;
    pointer-events: none;
    z-index: 10;
    top: 0;
    right: 0;
    width: spacing(18);
    height: 98%;
    background: linear-gradient(
      to left,
      rgbaColor(color(white), 1) 0%,
      rgbaColor(color(white), 0) 100%
    );
    content: '';
  }

  .mobile-tabs__title {
    padding: 0 spacing(18);
    h5 {
      color: color(black, light-2);
    }
  }
  .mobile-tabs__container {
    box-shadow: 0 2px 0 color(white);
    overflow-x: auto;
    padding: 0 spacing(12) spacing(18);
    border-bottom: 1px solid color(black, light-4);
    white-space: nowrap;
  }
  .mobile-tabs__section {
    display: inline-block;
    padding: 0 spacing(6);
    margin-right: spacing(24);
    font-weight: 700;
    &.mobile-tabs__section-active {
      a {
        color: color(orange);
        &:hover {
          color: color(orange, dark-1);
        }
      }
    }
    a {
      text-decoration: none;
      color: color(black, light-2);
      transition: transition();
      &:hover {
        color: color(black);
      }
    }
  }
}
