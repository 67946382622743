@import 'resources/scss/_foundation.scss';
.progress-bar {
  background-color: color(white, dark-2);
  border: 4px solid color(white);
  border-radius: border-radius(m);
  overflow: hidden;
  .progress-bar__steps {
    display: flex;
    .progress-bar__step {
      width: 100%;
      font-size: font-size(xs);
      text-transform: uppercase;
      color: color(black, light-2);
      position: relative;

      border-left: 1px solid color(white);
      &:first-child {
        border-left: 0;
      }
      .progress-bar__step__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 0;
      }
      .progress-bar__step__number {
        display: inline-block;
        span {
          background-color: color(white);
          display: inline-block;
          margin: 0 spacing(6) 0 spacing(12);
          width: spacing(18);
          height: spacing(18);
          line-height: spacing(18) - 1px;
          border-radius: 9px;
          text-align: center;
          border: 1px solid color(black, light-3);
        }
      }
      .progress-bar__step__name {
        display: inline-block;
      }

      &.progress-bar__step-active {
        background-color: color(black, light-4);
        color: color(black);
        .progress-bar__step__number {
          span {
            border: 1px solid color(white);
            color: color(white);
            background-color: color(black);
          }
        }
      }
      &.progress-bar__step-completed {
        background-color: color(ui-green);
        color: color(white);
        .progress-bar__step__number {
          opacity: 0.5;
          span {
            border: 1px solid color(white);
            color: color(ui-green, dark-1);
          }
        }
        .progress-bar__step__name {
          opacity: 0.5;
        }
      }
    }
  }

  &.progress-bar-modal {
    position: fixed;
    top: spacing(60);
    left: 0;
    width: 100%;
    z-index: 8888;
    border: none;
    border-radius: none;
  }

  &.progress-bar-inFlow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 8888;
  }

  //Mobile
  @media only screen and (max-width: breakpoint(tabletSD)) {
    height: spacing(9);
    border-bottom: 1px solid color(white);
    overflow: hidden;
    .progress-bar__step {
      border-left: 2px solid color(white);
    }
  }
}
