@import 'resources/scss/_foundation.scss';

.button {
  padding: 0 spacing(30);
  border-radius: border-radius();
  font-size: font-size();
  font-weight: 700;
  height: spacing(54);
  line-height: spacing(54);
  border: 1px solid color(white, dark-2);
  box-shadow: shadow(1);
  cursor: pointer;
  color: color(black, light-1);
  background-color: color(white);
  position: relative;
  transition: transition();
  overflow: hidden;
  margin: spacing(6) spacing(6) spacing(6) 0;
  text-decoration: none !important;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 1px solid color(white, dark-1);
    border-radius: border-radius();
    transition: transition();
  }

  &:hover {
    background-color: color(white, dark-1);
    color: color(black);
    box-shadow: shadow(3);
    text-decoration: none !important;
    &:before {
      border: 1px solid color(white);
    }
  }

  //Main Button
  &.button-main {
    background-color: color(orange);
    color: color(white);
    text-shadow: 0px -1px 0px color(orange, dark-1);
    border: none;
    &:before {
      border: 2px solid color(orange, light-1);
    }
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: border-radius();
      opacity: opacity(24);
      transition: transition();
      background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.24) 20%,
        rgba(255, 255, 255, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3dffffff', endColorstr='#ffffff',GradientType=1 );
      mix-blend-mode: overlay;
    }
    &:hover {
      background-color: color(orange, dark-1);
      color: color(orange, light-6);
      text-shadow: 0px 1px 0px color(orange, dark-3);
      &:before {
        border: 1px solid color(orange, light-1);
      }
    }
    //Main colors
    &.button-blue {
      background-color: color(blue);
      text-shadow: 0px -1px 0px color(blue, dark-1);
      &:before {
        border: 2px solid color(blue, light-1);
      }
      &:hover {
        background-color: color(blue, dark-1);
        color: color(blue, light-6);
        text-shadow: 0px 1px 0px color(blue, dark-3);
        &:before {
          border: 1px solid color(blue, light-1);
        }
      }
    }

    &.button-yellow {
      background-color: color(yellow);
      text-shadow: 0px -1px 0px color(yellow, dark-1);
      &:before {
        border: 2px solid color(yellow, light-1);
      }
      &:hover {
        background-color: color(yellow, dark-1);
        color: color(yellow, light-6);
        text-shadow: 0px 1px 0px color(yellow, dark-3);
        &:before {
          border: 1px solid color(yellow, light-1);
        }
      }
    }
    &.button-red {
      background-color: color(red);
      text-shadow: 0px -1px 0px color(red, dark-1);
      &:before {
        border: 2px solid color(red, light-1);
      }
      &:hover {
        background-color: color(red, dark-1);
        color: color(red, light-6);
        text-shadow: 0px 1px 0px color(red, dark-3);
        &:before {
          border: 1px solid color(red, light-1);
        }
      }
    }
    &.button-black {
      background-color: color(black);
      text-shadow: 0px -1px 0px color(black, light-1);
      &:before {
        border: 2px solid color(black, light-1);
      }
      &:hover {
        background-color: color(black, light-1);
        color: color(black, light-6);
        text-shadow: 0px 1px 0px color(black);
        &:before {
          border: 1px solid color(black, light-1);
        }
      }
    }
    &.button-ui-green {
      background-color: color(ui-green);
      text-shadow: 0px -1px 0px color(ui-green, dark-1);
      &:before {
        border: 2px solid color(ui-green, light-1);
      }
      &:hover {
        background-color: color(ui-green, dark-1);
        color: color(ui-green, light-6);
        text-shadow: 0px 1px 0px color(ui-green, dark-3);
        &:before {
          border: 1px solid color(ui-green, light-1);
        }
      }
    }
    &.button-ui-red {
      background-color: color(ui-red);
      text-shadow: 0px -1px 0px color(ui-red, dark-1);
      &:before {
        border: 2px solid color(ui-red, light-1);
      }
      &:hover {
        background-color: color(ui-red, dark-1);
        color: color(ui-red, light-6);
        text-shadow: 0px 1px 0px color(ui-red, dark-3);
        &:before {
          border: 1px solid color(ui-red, light-1);
        }
      }
    }
  }
  //Ghost Button
  &.button-ghost {
    background-color: color(white);
    color: color(black, light-1);
    text-shadow: none;
    border: 1px solid color(black, light-1);
    &:before {
      border: 2px solid color(white, dark-1);
    }
    &:after {
      display: none;
    }
    &:hover {
      background-color: color(white, dark-1);
      color: color(black);
      text-shadow: 0 1px 0 color(white);
      &:before {
        border: 1px solid color(white, dark-2);
        opacity: opacity(12);
      }
    }

    &.button-blue {
      color: color(blue);
      border: 1px solid color(blue);
      &:before {
        border: 2px solid color(blue, light-6);
      }
      &:hover {
        background-color: color(blue, light-6);
        color: color(blue, dark-1);
        text-shadow: none;
        &:before {
          border: 1px solid color(blue, light-1);
          opacity: opacity(12);
        }
      }
    }

    &.button-orange {
      color: color(orange);
      border: 1px solid color(orange);
      &:before {
        border: 2px solid color(orange, light-6);
      }
      &:hover {
        background-color: color(orange, light-6);
        color: color(orange, dark-1);
        text-shadow: none;
        &:before {
          border: 1px solid color(orange, light-1);
          opacity: opacity(12);
        }
      }
    }

    &.button-red {
      color: color(red);
      border: 1px solid color(red);
      &:before {
        border: 2px solid color(red, light-6);
      }
      &:hover {
        background-color: color(red, light-6);
        color: color(red, dark-1);
        text-shadow: none;
        &:before {
          border: 1px solid color(red, light-1);
          opacity: opacity(12);
        }
      }
    }

    &.button-yellow {
      color: color(yellow);
      border: 1px solid color(yellow);
      &:before {
        border: 2px solid color(yellow, light-6);
      }
      &:hover {
        background-color: color(yellow, light-6);
        color: color(yellow, dark-1);
        text-shadow: none;
        &:before {
          border: 1px solid color(yellow, light-1);
          opacity: opacity(12);
        }
      }
    }

    &.button-green {
      color: color(green);
      border: 1px solid color(green);
      &:before {
        border: 2px solid color(green, light-6);
      }
      &:hover {
        background-color: color(green, light-6);
        color: color(green, dark-1);
        text-shadow: none;
        &:before {
          border: 1px solid color(green, light-1);
          opacity: opacity(12);
        }
      }
    }

    &.button-ui-green {
      color: color(ui-green);
      border: 1px solid color(ui-green);
      &:before {
        border: 2px solid color(ui-green, light-6);
      }
      &:hover {
        background-color: color(ui-green, light-6);
        color: color(ui-green, dark-1);
        text-shadow: none;
        &:before {
          border: 1px solid color(ui-green, light-1);
          opacity: opacity(12);
        }
      }
    }

    &.button-ui-red {
      color: color(ui-red);
      border: 1px solid color(ui-red);
      &:before {
        border: 2px solid color(ui-red, light-6);
      }
      &:hover {
        background-color: color(ui-red, light-6);
        color: color(ui-red, dark-1);
        text-shadow: none;
        &:before {
          border: 1px solid color(ui-red, light-1);
          opacity: opacity(12);
        }
      }
    }
  }
  //Spaced Button
  &.button-spaced {
    margin-right: spacing(24);
  }
  //Fluid Button
  &.button-fluid {
    text-align: center;
    display: block;
    width: 100%;
    text-decoration: none !important;
  }
  //Disabled Button
  &.button-disabled {
    background-color: color(white, dark-2);
    text-shadow: none;
    color: color(black, light-2);
    box-shadow: 0 2px 0 color(white);
    cursor: default;
    &:before {
      border: 2px solid color(white, dark-2);
    }
    &:hover {
      background-color: color(white, dark-2);
      color: color(black, light-2);
      text-shadow: none;
      &:before {
        border: 2px solid color(white, dark-2);
      }
    }
  }
  //Small Button
  &.button-small {
    padding: 0 spacing(18);
    font-size: font-size(s);
    font-weight: 400;
    height: spacing(36);
    line-height: spacing(36);
  }

  //Back
  &.button-back {
    background-color: transparent;
    box-shadow: 0;
    border-color: color(black, light-3);
    position: absolute;
    top: spacing(12);
    left: spacing(12);
    font-size: 0;
    padding: 0;
    width: spacing(54);
    .button__arrow {
      position: absolute;
      top: spacing(6);
      left: spacing(18) + 3;
      opacity: 0.5;
      transition: transition();
    }
    &:hover {
      background-color: color(white);
      border-color: color(black, light-1);
      .button__arrow {
        opacity: 1;
      }
    }
    //Mobile
    @media only screen and (max-width: breakpoint(tabletSD)) {
      top: spacing(6);
      left: spacing(6);
    }
  }
}

a.button-link {
  text-decoration: none !important;
}
