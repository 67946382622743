@import 'resources/scss/_foundation.scss';
//Sections
.section {
  padding: spacing(60) 0;

  &.section-big {
    padding: spacing(120) 0;
  }
  &.section-small {
    padding: spacing(24) 0;
  }
  &.section-center {
    text-align: center;
    p,
    h1,
    h2,
    h3,
    h4,
    h5 {
      max-width: 100%;
    }
  }
  &.section-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &.section-alt {
    background-color: color(white, dark-2);
  }
  &.section-modal {
    padding-top: spacing(120);
  }
  &.section-white {
    background-color: color(white);
  }
  &.section-relative {
    position: relative;
  }

  @media only screen and (max-width: breakpoint(tabletSD)) {
    padding: spacing(30) 0;
    &.section-big {
      padding: spacing(60) 0;
    }
    &.section-small {
      padding: spacing(12) 0;
    }
  }
}
