@import 'resources/scss/_foundation.scss';
.background {
  &.opacity {
    &.opacity-80 {
      opacity: opacity(80);
    }
    &.opacity-50 {
      opacity: opacity(50);
    }
    &.opacity-24 {
      opacity: opacity(24);
    }
    &.opacity-12 {
      opacity: opacity(12);
    }
    &.opacity-8 {
      opacity: opacity(8);
    }
    &.opacity-4 {
      opacity: opacity(4);
    }
  }
  &.white {
    background-color: color(white);
    &.dark-1 {
      background-color: color(white, dark-1);
    }
    &.dark-2 {
      background-color: color(white, dark-2);
    }
  }

  &.black {
    background-color: color(black);
    p,
    h1,
    h2,
    h3,
    h4 {
      color: color(white);
    }
    &.light-1 {
      background-color: color(black, light-1);
    }
    &.light-2 {
      background-color: color(black, light-2);
    }
    &.light-3 {
      background-color: color(black, light-3);
    }
    &.light-4 {
      background-color: color(black, light-4);
    }
    &.light-5 {
      background-color: color(black, light-5);
    }
    &.light-6 {
      background-color: color(black, light-6);
    }
  }
  &.orange {
    background-color: color(orange);
    p,
    h1,
    h2,
    h3,
    h4 {
      color: color(white);
    }
    &.light-1 {
      background-color: color(orange, light-1);
    }
    &.light-2 {
      background-color: color(orange, light-2);
    }
    &.light-3 {
      background-color: color(orange, light-3);
    }
    &.light-4 {
      background-color: color(orange, light-4);
    }
    &.light-5 {
      background-color: color(orange, light-5);
    }
    &.light-6 {
      background-color: color(orange, light-6);
    }

    &.dark-1 {
      background-color: color(orange, dark-1);
    }
    &.dark-2 {
      background-color: color(orange, dark-2);
    }
    &.dark-3 {
      background-color: color(orange, dark-3);
    }
    &.dark-4 {
      background-color: color(orange, dark-4);
    }
    &.dark-5 {
      background-color: color(orange, dark-5);
    }
    &.dark-6 {
      background-color: color(orange, dark-6);
    }
  }
  &.blue {
    background-color: color(blue);
    p,
    h1,
    h2,
    h3,
    h4 {
      color: color(white);
    }
    &.light-1 {
      background-color: color(blue, light-1);
    }
    &.light-2 {
      background-color: color(blue, light-2);
    }
    &.light-3 {
      background-color: color(blue, light-3);
    }
    &.light-4 {
      background-color: color(blue, light-4);
    }
    &.light-5 {
      background-color: color(blue, light-5);
    }
    &.light-6 {
      background-color: color(blue, light-6);
    }

    &.dark-1 {
      background-color: color(blue, dark-1);
    }
    &.dark-2 {
      background-color: color(blue, dark-2);
    }
    &.dark-3 {
      background-color: color(blue, dark-3);
    }
    &.dark-4 {
      background-color: color(blue, dark-4);
    }
    &.dark-5 {
      background-color: color(blue, dark-5);
    }
    &.dark-6 {
      background-color: color(blue, dark-6);
    }
  }

  &.yellow {
    background-color: color(yellow);
    p,
    h1,
    h2,
    h3,
    h4 {
      color: color(white);
    }
    &.light-1 {
      background-color: color(yellow, light-1);
    }
    &.light-2 {
      background-color: color(yellow, light-2);
    }
    &.light-3 {
      background-color: color(yellow, light-3);
    }
    &.light-4 {
      background-color: color(yellow, light-4);
    }
    &.light-5 {
      background-color: color(yellow, light-5);
    }
    &.light-6 {
      background-color: color(yellow, light-6);
    }

    &.dark-1 {
      background-color: color(yellow, dark-1);
    }
    &.dark-2 {
      background-color: color(yellow, dark-2);
    }
    &.dark-3 {
      background-color: color(yellow, dark-3);
    }
    &.dark-4 {
      background-color: color(yellow, dark-4);
    }
    &.dark-5 {
      background-color: color(yellow, dark-5);
    }
    &.dark-6 {
      background-color: color(yellow, dark-6);
    }
  }

  &.red {
    background-color: color(red);
    p,
    h1,
    h2,
    h3,
    h4 {
      color: color(white);
    }
    &.light-1 {
      background-color: color(red, light-1);
    }
    &.light-2 {
      background-color: color(red, light-2);
    }
    &.light-3 {
      background-color: color(red, light-3);
    }
    &.light-4 {
      background-color: color(red, light-4);
    }
    &.light-5 {
      background-color: color(red, light-5);
    }
    &.light-6 {
      background-color: color(red, light-6);
    }

    &.dark-1 {
      background-color: color(red, dark-1);
    }
    &.dark-2 {
      background-color: color(red, dark-2);
    }
    &.dark-3 {
      background-color: color(red, dark-3);
    }
    &.dark-4 {
      background-color: color(red, dark-4);
    }
    &.dark-5 {
      background-color: color(red, dark-5);
    }
    &.dark-6 {
      background-color: color(red, dark-6);
    }
  }
  &.green {
    background-color: color(green);
    p,
    h1,
    h2,
    h3,
    h4 {
      color: color(white);
    }
    &.light-1 {
      background-color: color(green, light-1);
    }
    &.light-2 {
      background-color: color(green, light-2);
    }
    &.light-3 {
      background-color: color(green, light-3);
    }
    &.light-4 {
      background-color: color(green, light-4);
    }
    &.light-5 {
      background-color: color(green, light-5);
    }
    &.light-6 {
      background-color: color(green, light-6);
    }

    &.dark-1 {
      background-color: color(green, dark-1);
    }
    &.dark-2 {
      background-color: color(green, dark-2);
    }
    &.dark-3 {
      background-color: color(green, dark-3);
    }
    &.dark-4 {
      background-color: color(green, dark-4);
    }
    &.dark-5 {
      background-color: color(green, dark-5);
    }
    &.dark-6 {
      background-color: color(green, dark-6);
    }
  }

  &.darkBlue {
    background-color: color(darkBlue);
    p,
    h1,
    h2,
    h3,
    h4 {
      color: color(white);
    }
    &.light-1 {
      background-color: color(darkBlue, light-1);
    }
    &.light-2 {
      background-color: color(darkBlue, light-2);
    }
    &.light-3 {
      background-color: color(darkBlue, light-3);
    }
    &.light-4 {
      background-color: color(darkBlue, light-4);
    }
    &.light-5 {
      background-color: color(darkBlue, light-5);
    }
    &.light-6 {
      background-color: color(darkBlue, light-6);
    }

    &.dark-1 {
      background-color: color(darkBlue, dark-1);
    }
    &.dark-2 {
      background-color: color(darkBlue, dark-2);
    }
    &.dark-3 {
      background-color: color(darkBlue, dark-3);
    }
    &.dark-4 {
      background-color: color(darkBlue, dark-4);
    }
    &.dark-5 {
      background-color: color(darkBlue, dark-5);
    }
    &.dark-6 {
      background-color: color(darkBlue, dark-6);
    }
  }
  &.ui-green {
    background-color: color(ui-green);
    p,
    h1,
    h2,
    h3,
    h4 {
      color: color(white);
    }
    &.light-1 {
      background-color: color(ui-green, light-1);
    }
    &.light-2 {
      background-color: color(ui-green, light-2);
    }
    &.light-3 {
      background-color: color(ui-green, light-3);
    }
    &.light-4 {
      background-color: color(ui-green, light-4);
    }
    &.light-5 {
      background-color: color(ui-green, light-5);
    }
    &.light-6 {
      background-color: color(ui-green, light-6);
    }

    &.dark-1 {
      background-color: color(ui-green, dark-1);
    }
    &.dark-2 {
      background-color: color(ui-green, dark-2);
    }
    &.dark-3 {
      background-color: color(ui-green, dark-3);
    }
    &.dark-4 {
      background-color: color(ui-green, dark-4);
    }
    &.dark-5 {
      background-color: color(ui-green, dark-5);
    }
    &.dark-6 {
      background-color: color(ui-green, dark-6);
    }
  }
  &.ui-red {
    background-color: color(ui-red);
    p,
    h1,
    h2,
    h3,
    h4 {
      color: color(white);
    }
    &.light-1 {
      background-color: color(ui-red, light-1);
    }
    &.light-2 {
      background-color: color(ui-red, light-2);
    }
    &.light-3 {
      background-color: color(ui-red, light-3);
    }
    &.light-4 {
      background-color: color(ui-red, light-4);
    }
    &.light-5 {
      background-color: color(ui-red, light-5);
    }
    &.light-6 {
      background-color: color(ui-red, light-6);
    }

    &.dark-1 {
      background-color: color(ui-red, dark-1);
    }
    &.dark-2 {
      background-color: color(ui-red, dark-2);
    }
    &.dark-3 {
      background-color: color(ui-red, dark-3);
    }
    &.dark-4 {
      background-color: color(ui-red, dark-4);
    }
    &.dark-5 {
      background-color: color(ui-red, dark-5);
    }
    &.dark-6 {
      background-color: color(ui-red, dark-6);
    }
  }
}
