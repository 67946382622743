@import 'resources/scss/_foundation.scss';
.units-card {
  padding: spacing(30);
  background-color: color(white);
  transition: transition();
  width: 100%;
  border-radius: border-radius();
  margin-bottom: spacing(24);
  box-shadow: shadow(2), shadow(0, 1px);
  height: spacing(180);
  position: relative;

  //Mobile
  @media only screen and (max-width: breakpoint(mobileHD)) {
    height: auto;
    margin-bottom: spacing(12);
    padding: spacing(24);
  }

  .units-card__name {
    padding: 0 0 spacing(6);
    margin: 0;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media only screen and (max-width: breakpoint(mobileHD)) {
      text-transform: uppercase;
      white-space: normal;
      overflow: auto;
      background-color: color(white, dark-1);
      padding: spacing(24);
      margin: (0 - spacing(24)) (0 - spacing(24)) spacing(24);
    }
  }
  .units-card__details {
    padding: 0 0 spacing(6);
    margin: 0;
    //Mobile
    @media only screen and (max-width: breakpoint(mobileHD)) {
      padding: 0;
    }
  }
  .units-card__price {
    padding: 0 0 spacing(24);
    margin: 0;
    .units-card__discount-price {
      color: color(ui-green);
      .units-card__old-price {
        color: color(black, light-3);
        text-decoration: line-through;
        margin-left: spacing(6);
      }
    }

    //Mobile
    @media only screen and (max-width: breakpoint(mobileHD)) {
      font-size: heading-font-size(h4);
      line-height: heading-line-height(h4);
      padding: 0 0 spacing(6);
    }
  }
  .units-card__discount {
    padding: 0 0 spacing(6);
    margin: 0;
  }
  .units-card__action {
    position: absolute;
    bottom: spacing(18);
    right: spacing(18);
    opacity: 0;
    transition: transition();
    //Mobile
    @media only screen and (max-width: breakpoint(mobileHD)) {
      position: relative;
      opacity: 1;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      padding-top: spacing(18);
    }
    a {
      text-decoration: none;
    }
  }

  &:hover {
    box-shadow: shadow(4), shadow(0, 1px);
    .units-card__action {
      opacity: 1;
    }
  }

  &.units-card-vw {
    .units-card__price {
      color: color(blue);
    }
  }
  &.units-card-gm {
    .units-card__price {
      color: color(yellow, dark-1);
    }
  }
  &.units-card-nissan {
    .units-card__price {
      color: color(red);
    }
  }
}
