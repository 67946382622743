@import 'resources/scss/_foundation.scss';
.plans {
  .section.plans__plans-list {
    padding-top: spacing(240) !important;
    @media only screen and (max-width: breakpoint(tabletSD)) {
      padding-top: spacing(120) !important;
    }
  }
  .col-m-twelve {
    margin: spacing(6) 0 !important;
  }
  .plan-pay-link {
    padding-left: spacing(12);
    //Mobile
    @media only screen and (max-width: breakpoint(tabletSD)) {
      display: block;
      padding-left: 0;
      padding-right: spacing(6);
      margin-top: spacing(12);
    }
  }
}
