@import 'resources/scss/_foundation.scss';

.row-button {
  display: block;
  font-size: font-size(xs);
  line-height: line-height(xs);
  text-transform: uppercase;
  padding: spacing(24) spacing(24) (spacing(24) - 3);
  border-bottom: 1px solid color(black, light-4);
  color: color(black, light-2);
  text-decoration: none;
  background-color: color(white);
  &:hover {
    color: color(black);
    background-color: color(white, dark-1);
  }
  &.row-button-icon {
    position: relative;
    &:before {
      width: spacing(6);
      height: spacing(6);
      position: absolute;
      right: spacing(30);
      top: spacing(30) - 1;
      content: '';
      border-right: 2px solid color(black, light-2);
      border-bottom: 2px solid color(black, light-2);
      transform: rotate(-45deg);
    }
  }
}
